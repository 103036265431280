import { createAsyncThunk } from '@reduxjs/toolkit';
import { HttpMethods, Action } from '../constants/enums';
import { HttpLoadingHandler } from '../utils';
import { ContactRequest } from '../constants/classes';


export const lodgeContactRequest = createAsyncThunk( Action.LODGE_CONTACT_REQUEST, async ( data: ContactRequest, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.POST, '/system/requestContact', Action.LODGE_CONTACT_REQUEST, data, thunkAPI )
)

export const resetPassword = createAsyncThunk( Action.REQUEST_PASSWORD_RESET, async ( data: { email: string }, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.POST, `/system/passwordReset`, Action.REQUEST_PASSWORD_RESET, data , thunkAPI )
)

export const verifyResetMfa = createAsyncThunk( Action.VERIFY_PASSWORD_RESET, async ( data: { mfaCode: string }, thunkAPI ) => 
    await HttpLoadingHandler( HttpMethods.POST, `/system/resetApproval`, Action.VERIFY_PASSWORD_RESET, data, thunkAPI )
)

export const setPassword = createAsyncThunk( Action.SET_NEW_PASSWORD, async ( data: { newPasswd: string, confirmedPasswd: string }, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.POST, `/system/_reset`, Action.SET_NEW_PASSWORD, data, thunkAPI )
)