import axios, { AxiosRequestConfig } from 'axios';
import { HttpMethods } from '../constants/enums';
import { IResults,  } from '../constants/interfaces';
import { logout } from '../redux/slicers/Access';
import { UUID } from '../types';
import { global_EMPTY_UUID } from '../constants';
import { HttpStatusCodes, ErrorCodes, AppStatusCodes } from '../constants/enums';

const serverAPI = process.env.API_URL

export interface IHttpHandler {
    method: HttpMethods,
    endpoint: string,
    data: any,
    thunkAPI: any;
}

const errorCodes = [
    HttpStatusCodes.BAD_REQUEST,
    HttpStatusCodes.FORBIDDEN,
    HttpStatusCodes.REQUEST_TIMEOUT,
    HttpStatusCodes.INTERNAL_SERVER_ERROR,
    HttpStatusCodes.UNSUPPORTED_MEDIA_TYPE,
    HttpStatusCodes.PAYLOAD_TOO_LARGE,
    HttpStatusCodes.CONFLICT
]

export async function HttpHandler({ method, endpoint, data, thunkAPI }: IHttpHandler ): Promise< IResults > {
    
    const axiosConfig: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
            AID: thunkAPI.getState().Access.session?.AuthID as UUID || global_EMPTY_UUID,
            SUA: thunkAPI.getState().StepUpAuth?.StepUpAuthID as UUID || global_EMPTY_UUID
        },
        method,
        url: serverAPI + endpoint,
        data,
        withCredentials: true 
    }
    
    try {
        
        const response = await axios( axiosConfig );

        const { success, code, message, payload } = response.data;

        if ( process.env.NODE_ENV === 'development' ) {
            console.debug( `server = ${ serverAPI }` )
            console.debug( `endpoint = ${ endpoint } ${ JSON.stringify( response.data ) }` )
        }

        return {
            success: success,
            statusCode: response.status,
            code: Number( code ),
            message: message,
            payload: payload
        } as IResults;
        
    } catch ( error: any ) {

        let errorMessage: string;
        let errorCode: number;
        let statusCode: number;
        console.log( 'raw error: ', error )
        if ( error.request ) {
            // Network Error
            errorMessage = `Our servers are currently experiencing issues, please try again later`;
            errorCode = ErrorCodes.NETWORK_ERROR;
            statusCode = HttpStatusCodes.SERVICE_UNAVAILABLE;
        } else if ( error.response ) {
            // Server Error
            errorMessage = error.response.data?.message || 'Our servers are currently experiencing issues, please try again later';
            errorCode = error.response.data?.code ? error.response.data.code : ErrorCodes.HTTP_STATUS;
            statusCode = error.response.status;
        } else {
            // Unknown Error
            errorMessage = `An unexpected error has occurred, please try again later`;
            errorCode = ErrorCodes.UNKOWN_FUNCTION_ERROR;
            statusCode = HttpStatusCodes.INTERNAL_SERVER_ERROR;
        }

        if ( process.env.NODE_ENV === 'development' ) {
            console.error( `server = ${ serverAPI }` )
            console.error( `endpoint = ${ endpoint }, StatusCode: ${ error.response ? error.response.status: null }` )
            console.error( `error = message: ${ errorMessage }` )
        }
        console.log( 'Error Code: ', errorCode, 'Status Code: ', statusCode, 'Message: ', errorMessage )
        throw {
            success: false,
            statusCode: statusCode,
            code: errorCode,
            message: errorMessage,
            payload: null
        } as IResults

    }
}