import { IUserDetails, IObjectKeyValues, IValue } from '../../constants/interfaces';
import { stateAcronymConverter } from '../../components/commonUIF';

export function profileData( userDetails: IUserDetails ): { 
    personDetails: IObjectKeyValues, 
    employmentDetails: Array< IValue >
} {
    return {
        personDetails: {
            firstName:{
                key: '1000',
                label: 'First Name',
                value: userDetails?.FirstName ? userDetails?.FirstName : '(No Data)',
                isEdit: false
            },
            lastname: {
                key: '2000',
                label: 'Last Name',
                value: userDetails?.LastName ? userDetails?.LastName : '(No Data)',
                isEdit: false
            },
            fullName: {
                key: '3000',
                label: 'Full Name',
                value: userDetails?.FirstName && userDetails.LastName ? `${ userDetails?.FirstName } ${ userDetails?.LastName }` : '(No Data)',
                isEdit: false
            },
            preferredName: {
                key: '4000',
                label: 'Preferred Name',
                value: userDetails?.PreferredName ? userDetails?.PreferredName : '(No Data)',
                isEdit: true
            }
        },
        employmentDetails: [
            {
                key: '5000',
                label: 'Employer',
                value: userDetails.CompanyName ? userDetails.CompanyName : '(No Data)',
                isEdit: false
            },
            {
                key: '6000',
                label: 'Employer Address',
                value: userDetails.CompanyStreetAddress ? `${ userDetails.CompanyStreetAddress }, ${ userDetails.CompanyCity }, ${ stateAcronymConverter( userDetails.CompanyState )}, ${ userDetails.CompanyPostcode }` : '(No Data)',
                isEdit: false
            },
            {
                key: '7000',
                label: 'Position Title',
                value: userDetails.RoleTitle ? userDetails.RoleTitle : '(No Data)',
                isEdit: false
            },
            {
                key: '8000',
                label: 'Payroll ID',
                value: userDetails.PayrollNumber ? userDetails.PayrollNumber : '(No Data)',
                isEdit: false
            }
        ] 
    }
}