import React, { DragEvent, useState, useEffect } from 'react'
import { Grid, Typography, IconButton, Box, useTheme } from '@mui/material'
import { FileUploaderHeader, HiddenFileInput, FileDropZone, UploadCard } from '../../styled';
import { IReimbAttachment } from '../../../constants/interfaces';
import { UploadIcon } from '../Icons';
import { FileUploadCard } from './UploadCard';

interface FileUploaderProps {
    xsmobile: number;
    mobile: number;
    tablet: number;
    laptop: number;
    desktop: number;
    setFileUploads: ( files: Array< IReimbAttachment > ) => void;
    clearFiles: boolean;
}

const fileToBase64 = ( file: File ): Promise< string > => {
    return new Promise<string>(( resolve, reject ) => {
        const reader = new FileReader();
        reader.readAsDataURL( file );
        reader.onload = () => resolve(( reader.result as string ).split( ',' )[ 1 ]);
        reader.onerror = error => reject( error );
    });
};

export function FileUploader({ ...props }: FileUploaderProps) {
    const { xsmobile, mobile, tablet, laptop, desktop, clearFiles ,setFileUploads } = props;
    const [ dragging, setDragging ] = useState( false );
    const [ files, setFiles ] = useState< Array< IReimbAttachment >>( [] );
    const [ loading , setLoading ] = useState< boolean >( false );
    const [ error, setError ] = useState< boolean >( false );
    const colors = useTheme().palette;

    useEffect(() => {
        if ( files.length > 0 ) {
            setFileUploads( files );
        }
    }, [ files ]);

    useEffect(() => {
        if ( clearFiles ) {
            setFiles( [] );
        }
    }, [ clearFiles ]);

    // handlers
    const handleDragEnter = ( e: DragEvent< HTMLDivElement > ) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging( true );
    }
    const handleDragLeave = ( e: DragEvent< HTMLDivElement > ) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging( false );
    }
    const handleDrop = async ( e: DragEvent< HTMLDivElement > ) => {
        setLoading( true );
        try {
            e.preventDefault();
            e.stopPropagation();
            setDragging( false );
    
            // Retreive the dropped files
            let droppedFiles = Array.from( e.dataTransfer.files );
    
            for ( let file of droppedFiles ) {
                let data = await fileToBase64( file );
                setFiles( ( prevFiles) => [ 
                    ...prevFiles, 
                    { 
                        FileName: file.name, 
                        FileData: data, 
                        FileSize: `${ file.size }`,
                        FileType: file.type 
                    }
                ]);
    
            }
            setLoading( false );
        } catch ( error ) {
            setError( true );
            setLoading( false );
        }
    }

    async function onClickToUpload( event: React.ChangeEvent< HTMLInputElement > ) {
        setLoading( true );
        try {
            const uploads = Array.from( event.target.files || [] );
            for ( let file of uploads ) {
                let data = await fileToBase64( file );
                setFiles( ( prevFiles ) => [ 
                    ...prevFiles, 
                    { 
                        FileName: file.name, 
                        FileData: data, 
                        FileSize: `${ file.size }`,
                        FileType: file.type 
                    }
                ]);
            }
            setLoading( false );
        } catch {
            setError( true );
            setLoading( false );
        }
    } 

    const onDelete = ( fileName: string ) => setFiles( files.filter( file => file.FileName !== fileName ) );

    return (
        <Grid item
            xsmobile={ xsmobile } mobile={ mobile } tablet={ tablet } laptop={ laptop } desktop={ desktop }
        >
            <FileUploaderHeader>
                <Typography variant='h6'>Upload Supporting Files</Typography>
            </FileUploaderHeader>

            <FileDropZone className='file-dropzone'
                //sx={{ height: `${ heightRef-8 }px` }}
                onDragEnter={ handleDragEnter }
                onDragLeave={ handleDragLeave }
                onDragOver={ handleDragEnter }
                onDrop={ handleDrop }
            >

                { dragging && <Typography variant='tableHeader'>Drop here</Typography> }

                { !dragging && (
                    <React.Fragment>

                        <IconButton 
                            size='large'
                            component={ 'label' }
                            role={ undefined }
                            tabIndex={ -1 }
                            sx={{
                                color: colors.primary.main,
                                '&:hover': {
                                    color: colors.common.skyCyan
                                }
                            }}
                        >
                            <UploadIcon size='large' outlined color='inherit' />
                            <HiddenFileInput 
                                type='file'
                                multiple
                                onChange={ onClickToUpload } 
                            />
                        </IconButton>

                        <Typography variant='tableHeader'
                            component={ 'label' }
                            sx={{
                                textDecoration: 'none',
                                cursor: 'pointer'
                            }}
                        >
                                <Typography
                                    variant='tableHeader'
                                    sx={{ 
                                        textDecoration: 'underline', 
                                        cursor: 'pointer',
                                        color: colors.primary.main,
                                        '&:hover' : {
                                            color: colors.common.skyCyan
                                        }
                                    }}
                                >
                                    Click to upload
                                </Typography>
                                <HiddenFileInput 
                                    type='file'
                                    multiple
                                    onChange={ onClickToUpload } 
                                /> or drag & drop files here
                        </Typography>

                    </React.Fragment>
                )}
                <Box
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginTop: '1.5rem',
                        width: '100%',
                        height: '100%',
                        whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
                    }}
                >
                    <Typography variant='dataValue'
                        sx={{ color: colors.text.secondary }}
                    >
                        PNG, JPG, HEIC, PDF, DOCX, XLSX or CSV (max. 5MB)
                    </Typography>
                </Box>
            </FileDropZone>

            { files.length > 0 && (
                files.map(( file, index ) => (
                    <FileUploadCard
                        key={ index }
                        fileName={ file.FileName }
                        loading={ loading }
                        size={ file.FileSize }
                        error={ error } 
                        onDelete={ () => onDelete( file.FileName ) } 
                    />
                )
            ))}
  
        </Grid>
    )
}