import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { IUserEmail } from '../../../constants/interfaces';
import { EmailType } from '../../../constants/enums';
import { addNewEmail } from '../../../redux/AccessThunks';
import { AppDispatch } from '../../../redux/Store';
import { BasicDropDownMenu, StandardTextInput } from '../../../components/UI';
import { Dialog_DetailEdit } from '../../../components/UI';


interface AddEmailDialogProps {
    show: boolean;
    close: () => void;
    contactID: number;
    dispatch: AppDispatch;
}

export const AddEmailDialog = ({ ...props }: AddEmailDialogProps ) =>  {
    const { show, close, contactID, dispatch } = props
    const [ newEmail, setNewEmail ] = useState< IUserEmail >({
        ContactID: contactID,
        EmailID: 0,
        EmailTypeID: EmailType.PERSONAL,
        EmailType: '',
        EmailAddress: '',
        IsPrimary: false,
        IsSendAllIncluded: false
    })

    const onCancel = () => {
        setNewEmail({
            ContactID: contactID,
            EmailID: 0,
            EmailTypeID: EmailType.PERSONAL,
            EmailType: '',
            EmailAddress: '',
            IsPrimary: false,
            IsSendAllIncluded: false
        })
        close()
    }

    const onAddEmail = async () => {

        // Validation Logic Required Like Crazy.... Help dont have time! \\
        if ( newEmail.EmailAddress === '' ) {
            return alert( 'Cannot add an email if one is not provided?' )
        }
        if ( newEmail.EmailAddress.length < 3 ) {
            return alert( 'Please enter a valid email' )
        }
        if ( newEmail.EmailAddress.length > 250 ) {
            return alert( 'Please enter a valid email' )
        }
        if ( EmailType[ newEmail.EmailTypeID ] === '' ) {
            return alert( 'Email Type is required' )
        }

        await dispatch( addNewEmail( newEmail ))
        setNewEmail({
            ContactID: contactID,
            EmailID: 0,
            EmailTypeID: EmailType.PERSONAL,
            EmailType: '',
            EmailAddress: '',
            IsPrimary: false,
            IsSendAllIncluded: false
        })
        close()
    }

    return (
        <Dialog_DetailEdit
            show={ show }
            title='Add New Email'
            confirmLabel='Add'
            cancelLabel='Cancel'
            slideDirection='up'
            onConfirm={ onAddEmail }
            onCancel={ onCancel }
        >
            <form>
                <Grid
                    container
                    direction='column'
                    spacing={ 1 }
                >
                    <Grid item>
                        <BasicDropDownMenu
                            menuLabel='Email Type'
                            selectID='EmailType'
                            size='small'
                            options={[
                                { key: 1, label: 'Personal', value: EmailType.PERSONAL },
                                { key: 2, label: 'Secondary', value: EmailType.SECONDARY },
                                { key: 3, label: 'Work', value: EmailType.BUSINESS },
                                { key: 4, label: 'Other', value: EmailType.OTHER }
                            ]}
                            onChange={ ( v ) => setNewEmail({ ...newEmail, EmailTypeID: v  }) }
                        />
                    </Grid>

                    <Grid item>
                        <StandardTextInput
                            variant='standard'
                            size='small'
                            label='New Email Address'
                            value={ newEmail.EmailAddress }
                            type='text'
                            onChange={ ( v ) => setNewEmail({ ...newEmail, EmailAddress: v }) }
                        />
                    </Grid>
                </Grid>
            </form>
        </Dialog_DetailEdit>
    )
}