import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { DesktopPasswdReset } from "./DesktopPasswdReset";
import { useAppDispatch, useAppSelector } from "../../redux/Store";
import { validatePassword, validateMfaCode, validateEmail } from "../../utils/commons";
import { useNavigate, useLocation } from "react-router-dom";
import { RoutePaths, AppStatusCodes } from "../../constants/enums";
import { useMediaWidth } from "../../hooks";
import { resetPassword, verifyResetMfa, setPassword } from "../../redux/AppStateThunks";


export default function PasswdReset() {
    const { Authenticated } = useAppSelector(( state ) => state.Access )
    const [ view, setView ] = useState< 'user' | 'mfa' | 'verify' | 'reset' | 'complete' >( 'user' )
    const [ currentStep, setCurrentStep ] = useState< number >( 0 )
    const [ username, setUsername ] = useState< string >( '' )
    const [ usernameError, setUsernameError ] = useState< boolean >( false )
    const [ helperText, setHelperText ] = useState< string >( '' )
    const [ newPassword, setNewPassword ] = useState< string >( '' )
    const [ confirmPassword, setConfirmPassword ] = useState< string >( '' )
    const [ passwordError, setPasswordError ] = useState< boolean >( false )
    const [ validPassword, setValidPassword ] = useState< boolean >( true )
    const [ inValidMessage, setInValidMessage ] = useState< string >( '' )
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const theme = useTheme()
    const isTablet = useMediaWidth({ direction: 'down', breakPoint: 'tablet' })
    

    useEffect(() => {
        switch( view ) {
            case 'user':
                setCurrentStep( 0 )
                break
            case 'mfa':
                setCurrentStep( 1 )
                break
            case 'verify':
                setCurrentStep( 1 )
                break
            case 'reset':
                setCurrentStep( 2 )
                break
            case 'complete':
                setCurrentStep( 3 )
                break
            default:
                setCurrentStep( 0 )
        }
    }, [ view ])

    useEffect(() => {
        const criteriaMessage: string = isTablet ? 
            'Criteria: min 10 chars, upper & lowercase, numbers, & special char'
            :
            'Password must include numbers, lower & uppercase, special characters, and minumum 10 characters'
        ;
        const newLength: boolean = newPassword.length >= 10;
        const conLength: boolean = confirmPassword.length >= 10;
        const criteriaNew: boolean = validatePassword( newPassword )
        const criteriaCon: boolean = validatePassword( confirmPassword )
        const strictMatch: boolean = newPassword === confirmPassword

        if ( !newLength ) {
            setValidPassword( true )
            setInValidMessage( '' )
            return;
        }

        if ( !criteriaNew ) {
            setValidPassword( false )
            setInValidMessage( criteriaMessage )
            return
        }  
        if ( criteriaNew ) {
            setValidPassword( true )
            setInValidMessage( '' )
        }
        
        if ( !conLength ) return;
            
        
        if ( !criteriaCon ) {
            setValidPassword( false )
            setInValidMessage( criteriaMessage )
            return
        }  
        if ( criteriaCon ) {
            setValidPassword( true )
            setInValidMessage( '' )
        }

        if ( !strictMatch ) {
            setValidPassword( false )
            setInValidMessage( 'Passwords do not match' )
            return
        }

        setValidPassword( true )
        setInValidMessage( '' )
    },[ newPassword, confirmPassword ])

    async function onSubmitUsername() {
        if ( !validateEmail( username )) {
            setUsernameError( true )
            setHelperText( 'Please enter a valid email address' )
            return
        }
        setUsernameError( false )
        setHelperText( '' )

        const { success, code } = await dispatch( resetPassword({ email: username })).unwrap()
        if ( success && code === AppStatusCodes.MFA_REQUIRED ) {
            setView( 'mfa' )
            return
        }
        alert( 'An error occurred. Please try again' )
    }

    async function onSubmitMFA( mfaCode: string ) {
        if ( !validateMfaCode( mfaCode )) {
            setUsernameError( true )
            setHelperText( 'Please enter a valid 6-digit code' )
            return
        }
        setUsernameError( false )
        setHelperText( '' )
        const { success, code } = await dispatch( verifyResetMfa({ mfaCode: mfaCode })).unwrap()
        if ( success && code === AppStatusCodes.PASSWORD_RESET_APPROVED ) {
            setView( 'reset' )
            return
        }
        alert( 'An error occurred. Please try again' )
    }

    async function onSubmitNewPassword() {
        if ( newPassword !== confirmPassword ) {
            setPasswordError( true )
            setHelperText( 'Passwords do not match' )
            return
        }
        if ( !validatePassword( newPassword )) {
            setPasswordError( true )
            setHelperText( 'Password does not meet criteria' )
            return
        }
        const { success, code } = await dispatch( setPassword({ newPasswd: newPassword, confirmedPasswd: confirmPassword })).unwrap()
        if ( success && code === AppStatusCodes.PASSWORD_RESET_SUCCESS ) {
            setView( 'complete' )
            navigate( RoutePaths.LOGIN )
            return
        }
        alert( 'An error occurred. Please try again' )
    }

    const cancelReset = () => navigate( RoutePaths.LOGIN, { state: { from: location.pathname }, replace: true } )
    
    return (
        <DesktopPasswdReset 
            currentView={ view }
            currentStep={ currentStep }
            username={ username }
            error={ usernameError }
            helpText={ helperText }
            setUsername={ setUsername }
            newPassword={ newPassword }
            setNewPassword={ setNewPassword }
            confirmPassword={ confirmPassword }
            setConfirmPassword={ setConfirmPassword }
            isValid={ validPassword }
            inValidMessage={ inValidMessage }
            submitUsername={ onSubmitUsername }
            submitMFA={ onSubmitMFA }
            submitNewPassword={ onSubmitNewPassword }
            onCancel={ cancelReset }
        />
    )
}