import { OperatingCostSelectOptions } from '../../../../constants';
import { IReimbursmentSubmission } from "../../../../constants/interfaces";
import { Grid, Typography, InputAdornment } from '@mui/material';
import { BasicButton } from '../../../../components/UI';
import { SendIcon } from '../../../../components/UI/Icons';
import { operCostIDConverter, currencyFormatter } from '../../../../components/commonUIF';
import {
    DataGroup,
    DataFieldConditional,
    BasicDropDownMenu,
    BasicSelectOption,
    StandardTextInput,
    RadioToggleInput,
    DateSelector,
} from '../../../../components/UI';


interface ReimbursementFormProps {
    newReimbursement: IReimbursmentSubmission;
    packages: Array< BasicSelectOption >;
    bankAccountOptions: Array< BasicSelectOption >;
    selectedBenefit: number;
    selectedOperCost: number;
    selectedAccount: number;
    setReimbursementValues: ( value: IReimbursmentSubmission ) => void;
    handleBenefitSelection: ( value: any ) => void;
    unlockForm: boolean;
    unlockSubmit: boolean;
    onSubmit: () => void;
    onKeyDown: ( event: React.KeyboardEvent ) => void;
    mode: boolean;
    xsmobile: number;
    mobile: number;
    tablet: number;
    laptop: number;
    desktop: number;
}

export const ReimbursementForm = ({ 
    newReimbursement,
    packages,
    bankAccountOptions,
    setReimbursementValues,
    handleBenefitSelection,
    onSubmit,
    onKeyDown,
    ...props
}: ReimbursementFormProps ) =>  (
    <Grid item
        xsmobile={ props.xsmobile }
        mobile={ props.mobile }
        tablet={ props.tablet }
        laptop={ props.laptop }
        desktop={ props.desktop }
    >
        <form onKeyDown={ onKeyDown }>
            <DataGroup title='Reimbursement Form'>
                {/* Select Package to Claim Against  */}
                <Grid item
                    xsmobile={ 4 }
                    mobile={ 5 }
                    tablet={ 3 }
                    laptop={ 4 }
                    desktop={ 4 }
                >
                    <BasicDropDownMenu
                        menuLabel='Select Lease Package to Claim Against'
                        selectID='packageSelect'
                        options={ packages }
                        value={ props.selectedBenefit }
                        size='small'
                        onChange={ ( value ) => handleBenefitSelection( value ) }
                    />
                </Grid>

                {/* Operating Cost Type Selection */}
                <Grid item
                    xsmobile={ 4 }
                    mobile={ 5 }
                    tablet={ 2 }
                    laptop={ 3 }
                    desktop={ 4 }
                >
                    <BasicDropDownMenu
                        menuLabel='Select Vehicle Operating Cost'
                        selectID='costSelect'
                        options={ OperatingCostSelectOptions }
                        value={ props.selectedOperCost }
                        size='small'
                        disabled={ newReimbursement.BenefitID === 0 }
                        onChange={ ( value ) => setReimbursementValues({ ...newReimbursement, BenefitOperCostTypeID: value, BenefitOperCostName: `${ operCostIDConverter( value ) }` }) }
                    />
                </Grid>

                {/* Select Reimbursement Account */}
                <Grid item
                    xsmobile={ 4 }
                    mobile={ 5 }
                    tablet={ 3 }
                    laptop={ 3 }
                    desktop={ 4 }
                >
                    <BasicDropDownMenu
                        menuLabel='Select Account for Reimbursement'
                        selectID='bankAccountSelect'
                        options={ bankAccountOptions }
                        value={ props.selectedAccount}
                        size='small'
                        disabled={ newReimbursement.BenefitOperCostTypeID === 0 }
                        onChange={ ( value ) => setReimbursementValues({ ...newReimbursement, BankAccountID: value }) }
                    />
                </Grid>

                {/* Supplier Company Name */}
                <Grid item
                    xsmobile={ 4 }
                    mobile={ 5 }
                    tablet={ 3 }
                    laptop={ 10 }
                    desktop={ 4 }
                >
                    <StandardTextInput
                        label={ 'Supplier' }
                        placeholder='Supplier Name'
                        type='text'
                        variant='standard'
                        size='small'
                        disabled={ props.unlockForm }
                        value={ newReimbursement.SupplierCompanyName === null ? '' : `${ newReimbursement.SupplierCompanyName }` }
                        onChange={ ( value ) => setReimbursementValues({ ...newReimbursement, SupplierCompanyName: value }) }
                    />
                </Grid>

                {/* Purchase Date */}
                <Grid item
                    xsmobile={ 4 }
                    mobile={ 5 }
                    tablet={ 2 }
                    laptop={ 4 }
                    desktop={ 4 }
                >
                    <DateSelector
                        label='Purchase Date'
                        useDefaultValue
                        value={ newReimbursement.PurchaseDate }
                        variant='standard'
                        size='small'
                        disabled={ props.unlockForm }
                        onChange={ ( date: string ) => setReimbursementValues({ ...newReimbursement, PurchaseDate: date }) }
                    />
                </Grid>

                {/* Supplier Reference */}
                <Grid item
                    xsmobile={ 2 }
                    mobile={ 3 }
                    tablet={ 2 }
                    laptop={ 4 }
                    desktop={ 2 }
                >
                    <StandardTextInput
                        label={ 'Reference/Invoice No.' }
                        placeholder='Reference or Invoice Number'
                        type='text'
                        variant='standard'
                        size='small'
                        disabled={ props.unlockForm || newReimbursement.IsNoReferenceNumber === true }
                        value={ newReimbursement.SupplierRefText === null ? '' : `${ newReimbursement.SupplierRefText }` }
                        onChange={ ( value ) => setReimbursementValues({ ...newReimbursement, SupplierRefText: value }) }
                    />
                </Grid>

                {/* No Reference Available  */}
                <Grid item
                    xsmobile={ 2 }
                    mobile={ 2 }
                    tablet={ 1 }
                    laptop={ 2 }
                    desktop={ 2 }
                >
                    <RadioToggleInput
                        label={ 
                            <Typography 
                                variant='caption'
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                No Reference No.
                            </Typography> }
                        labelPlacement='top'
                        variant='filled'
                        size='small'
                        disabled={ props.unlockForm }
                        defaultChecked={ false }
                        onChange={ ( value ) => setReimbursementValues({ ...newReimbursement, IsNoReferenceNumber: value }) }
                    />
                </Grid>

                {/* Odometer Input */}
                <Grid item
                    xsmobile={ 4 }
                    mobile={ 5 }
                    tablet={ 3 }
                    laptop={ 3 }
                    desktop={ 4 }
                >
                    <StandardTextInput
                        label={ 'Odo Reading' }
                        placeholder="Odometer KM's"
                        type='number'
                        variant='standard'
                        size='small'
                        disabled={ props.unlockForm }
                        value={ newReimbursement.Odometer === null ? '' : `${ newReimbursement.Odometer }` }
                        onChange={ ( value ) => setReimbursementValues({ ...newReimbursement, Odometer: value }) }
                    />
                </Grid>

                {/* Amount Input */}
                <Grid item
                    xsmobile={ 4 }
                    mobile={ 5 }
                    tablet={ 2 }
                    laptop={ 3 }
                    desktop={ 4 }
                >
                    <StandardTextInput
                        label={ 'Amount (Inc. GST)' }
                        placeholder='0.00'
                        type='text'
                        variant='standard'
                        size='small'
                        disabled={ props.unlockForm }
                        value={ newReimbursement.AmountIncTax }
                        onChange={ ( value ) => setReimbursementValues({ ...newReimbursement, AmountIncTax: value }) }
                        startAdornment={ <InputAdornment position='start' >$</InputAdornment> }
                        inputProps={{
                            step: '0.01'
                        }}
                    />
                </Grid>

                {/* Amount Tax */}
                <Grid item
                    xsmobile={ 2 } mobile={ 3 } tablet={ 2 } laptop={ 2 } desktop={ 2 }
                >
                    <StandardTextInput
                        label={ 'GST Amount' }
                        type='text'
                        variant='standard'
                        size='small'
                        value={ 
                            newReimbursement.IsGSTOverride === false ? 
                            currencyFormatter.format( parseInt( newReimbursement.AmountIncTax || '0', 10 ) / 11 )
                            : 
                            newReimbursement.AmountTax
                        }
                        disabled={ props.unlockForm || newReimbursement.IsGSTOverride === false }
                        onChange={ ( value ) => setReimbursementValues({ ...newReimbursement, AmountTax: value }) }
                        startAdornment={ <InputAdornment position='start' >$</InputAdornment> }
                        inputProps={{
                            step: '0.01'
                        }}
                    />
                </Grid>

                {/* GST Overide */}
                <Grid item
                    xsmobile={ 2 }
                    mobile={ 2 }
                    tablet={ 1 }
                    laptop={ 2 }
                    desktop={ 2 }
                >
                    <RadioToggleInput
                        label={
                            <Typography
                                variant='caption'
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                Overide GST
                            </Typography> 
                        }
                        labelPlacement='top'
                        variant='filled'
                        size='small'
                        disabled={ props.unlockForm }
                        defaultChecked={ false }
                        onChange={ ( value ) => setReimbursementValues({ ...newReimbursement, IsGSTOverride: value }) }
                    />
                </Grid>

                {/* User Comments */}
                <Grid item
                    xsmobile={ 4 }
                    mobile={ 5 }
                    tablet={ 8 }
                    laptop={ 10 }
                    desktop={ 12 }
                >
                    <StandardTextInput
                        label={ 'Comment' }
                        isTextArea
                        textAreaRows={ 3 }
                        type='text'
                        variant='outlined'
                        size='small'
                        disabled={ props.unlockForm }
                        value={ newReimbursement.UserComment === null ? '' : `${ newReimbursement.UserComment }` }
                        onChange={ ( value ) => setReimbursementValues({ ...newReimbursement, UserComment: value }) }
                    />
                </Grid>

                <Grid item
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    xsmobile={ 4 }
                    mobile={ 5 }
                    tablet={ 8 }
                    laptop={ 12 }
                    desktop={ 12 }
                >
                    <BasicButton
                        text='Submit Reimbursement'
                        icon={ <SendIcon outlined={ props.mode } size='small' color='inherit' /> }
                        iconPosition='end'
                        onClickHandler={ onSubmit }
                        size='small'
                        disabled={ props.unlockSubmit }
                    />
                </Grid>

            </DataGroup>
        </form>
    </Grid>
)