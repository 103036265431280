import React, { useState, useContext } from 'react';
import { PaletteMode } from '@mui/material';
import { DataGroup, DataFieldConditional } from '../../components/UI';
import { IPreferences, IUserEmail, IUserPhone, IPackageBasicDetails } from '../../constants/interfaces';
import { switchMFA } from '../../components/commonUIF';
import { MFAMethod, PhoneType, AppStatusCodes } from '../../constants/enums';
import { BasicDropDownMenu } from '../../components/UI';
import { useAppDispatch, useAppSelector } from '../../redux/Store';
import { setPackageBasicDetail } from '../../redux/slicers/Access';
import { updatePreferences } from '../../redux/AccessThunks';
import { addAlert } from '../../redux/slicers/AppState';
import { ColorModeContext } from '../../theme';
import { formatPhoneByType } from '../../components/commonUIF';


interface PreferenceComponentProps {
    existingPreferences: IPreferences;
    emailAddresses: Array< IUserEmail >;
    phoneNumbers: Array< IUserPhone >;
    xsmobile: number;
    mobile: number;
    tablet: number;
    laptop: number;
    desktop: number;
}

export function UserPreferences({ existingPreferences, emailAddresses, phoneNumbers, ...props }: PreferenceComponentProps ) {
    const { xsmobile, mobile, tablet, laptop, desktop } = props;
    const { session, basicPackageDetail } = useAppSelector( state => state.Access )
    const [ editPrefs, setEditPrefs ] = useState< 'view' | 'edit' >( 'view' )
    const [ mfaTargetOptions, setMfaTargetOptions ] = useState< Array<{ key: any, label: any, value: any }>>([])
    const [ preferences, setPreferences ] = useState< IPreferences >({ ...existingPreferences })
    const dispatch = useAppDispatch()
    const colorMode = useContext( ColorModeContext )

    let existingMFaTarget = preferences.MfaMethod === MFAMethod.SMS ? 
        phoneNumbers.find( phone => phone.PhoneID === preferences.MfaTarget )?.PhoneNumber 
        : 
        emailAddresses.find( email => email.EmailID === preferences.MfaTarget )?.EmailAddress
    ;

    const prefSets = {
        MfaMethod: {
            key: '1001',
            label: 'Multi-Factor Authentication',
            value: switchMFA( preferences.MfaMethod ),
            isEdit: true
        },
        MfaTarget: {
            key: '1002',
            label: 'MFA Destination',
            value:  preferences.MfaMethod === MFAMethod.SMS ? 
                formatPhoneByType( `${ existingMFaTarget }`, PhoneType.MOBILE ) 
                : 
                existingMFaTarget,
            isEdit: true
        },
        ThemMode: {
            key: '1003',
            label: 'Color Scheme',
            value: preferences.ThemeMode,
            isEdit: true
        },
        HideEndedPackages: {
            key: '1004',
            label: 'Hide Ended Packages',
            value: preferences.HideEndedPackages ? 'Yes' : 'No',
            isEdit: true
        }
    }

    const mfaSelectMenu = [
        {
            key: MFAMethod.SMS,
            label: 'SMS',
            value: MFAMethod.SMS
        },
        {
            key: MFAMethod.EMAIL,
            label: 'Email',
            value: MFAMethod.EMAIL
        }
        // {
        //     key: MFAMethod.GOOGLE,
        //     label: 'Google Authenticator',
        //     value: MFAMethod.GOOGLE
        // },
        // {
        //     key: MFAMethod.MICROSOFT,
        //     label: 'Microsoft Authenticator',
        //     value: MFAMethod.MICROSOFT
        // }
    ]

    let phoneValues = phoneNumbers.map( ( phone, index ) => {
        return {
            key: index,
            label: phone.PhoneNumber,
            value: phone.PhoneID
        }
    })

    let emailValues = emailAddresses.map( ( email, index ) => {
        return {
            key: index,
            label: email.EmailAddress,
            value: email.EmailID
        }
    })

    const colorSchemeOptions = [
        {
            key: 'light',
            label: 'Light',
            value: 'light'
        },
        {
            key: 'dark',
            label: 'Dark',
            value: 'dark'
        }
    ]

    const hidePackagesOptions = [
        {
            key: 'yes',
            label: 'Yes',
            value: true
        },
        {
            key: 'no',
            label: 'No',
            value: false
        }
    ]

    const handleMfaMethodChange = ( value: MFAMethod ) => {
        switch( value ) {
            case MFAMethod.SMS:
                setMfaTargetOptions( phoneValues )
                break
            case MFAMethod.EMAIL:
                setMfaTargetOptions( emailValues )
                break
        }
        setPreferences({ ...preferences, MfaMethod: value })
    }

    const handleColorSchemeChange = ( value: PaletteMode ) => {
        setPreferences({ ...preferences, ThemeMode: value })
        colorMode.toggleColorMode()
    }

    const handleHidePackagesChange = ( value: boolean ) => {
        setPreferences({ ...preferences, HideEndedPackages: value })
        if ( value ) {
            let newBasicList: Array< IPackageBasicDetails > = basicPackageDetail.filter( benefit => benefit.BenefitStatus !== 'Ended' )
            dispatch( setPackageBasicDetail({ success: true, code: 0, payload: newBasicList }) )
        }
    }

    const onSave = async () => {
        let response = await dispatch( updatePreferences({ UserID: session.UserID, updatedPrefs: preferences }) ).unwrap()
        let { success, code } = response
        console.log( success, code )
        if ( code === AppStatusCodes.MFA_REQUIRED ) {
            setEditPrefs( 'view' )
            dispatch( addAlert({ id: Date.now(), message: 'Please enter the 6 digit MFA code', type: 'warning' }) )
        } else {
            setEditPrefs( 'view' )
            dispatch( addAlert({ id: Date.now(), message: 'Error Saving Preferences', type: 'error' }) )
        }
    }

    const handleCancel = () => {
        setEditPrefs( 'view' )
        setPreferences( existingPreferences )
    }

    return (
        <DataGroup
            title="Preferences & Settings"
            isEditable
            hideBackground
            editing={ editPrefs === 'edit' }
            onEditClick={ () => setEditPrefs( 'edit' ) }
            onSaveClick={ onSave }
            onCancelClick={ handleCancel }
            xsmobile={ xsmobile } mobile={ mobile } tablet={ tablet } laptop={ laptop } desktop={ desktop }
        >
            <DataFieldConditional
                condition={ editPrefs === 'view' }
                data={ prefSets.MfaMethod }
                xsmobile={ 4 } mobile={ 5 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
            >
                <BasicDropDownMenu
                    menuLabel='Multi-Factor Authentication'
                    selectID='mfaMethod'
                    size='small'
                    options={ mfaSelectMenu }
                    defaultOption={{
                        key: 0,
                        label: 'Select an Option',
                        value: ''
                    }}
                    onChange={ handleMfaMethodChange }
                />
            </DataFieldConditional>

            <DataFieldConditional
                condition={ editPrefs === 'view' }
                data={ prefSets.MfaTarget }
                xsmobile={ 4 } mobile={ 5 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
            >
                <BasicDropDownMenu
                    menuLabel={ 'Choose Destination' }
                    selectID='mfaTarget'
                    size='small'
                    options={ mfaTargetOptions }
                    defaultOption={{
                        key: 0,
                        label: 'Select an Option',
                        value: ''
                    }}
                    onChange={ ( v ) => setPreferences({ ...preferences, MfaTarget: v }) }
                />
            </DataFieldConditional>

            <DataFieldConditional
                condition={ editPrefs === 'view' }
                data={ prefSets.ThemMode }
                xsmobile={ 4 } mobile={ 5 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
            >
                <BasicDropDownMenu
                    menuLabel={ 'Choose Color Scheme' }
                    selectID='colorScheme'
                    size='small'
                    options={ colorSchemeOptions }
                    defaultOption={{
                        key: 0,
                        label: 'Select an Option',
                        value: ''
                    }}
                    onChange={ handleColorSchemeChange }
                />
            </DataFieldConditional>

            <DataFieldConditional
                condition={ editPrefs === 'view' }
                data={ prefSets.HideEndedPackages }
                xsmobile={ 4 } mobile={ 5 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
            >
                <BasicDropDownMenu
                    menuLabel={ 'Hide Ended Packages' }
                    selectID='endenPackages'
                    size='small'
                    options={ hidePackagesOptions }
                    defaultOption={{
                        key: 0,
                        label: 'Select an Option',
                        value: ''
                    }}
                    onChange={ handleHidePackagesChange }
                />
            </DataFieldConditional>

        </DataGroup>
    )
}