import { IReimbHistoryItem } from '../../constants/interfaces';
import { ReimbStatus, BankAccountType, BankAccApprovalStatus } from '../../constants/enums';
import { daysBetweenDates } from './DatesAndTimes';

export * from './DatesAndTimes';
export * from './FileDownload';


export const validateURL = ( url: string ): boolean => {
	const regex =
		/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
	return regex.test( String( url ).toLowerCase() );
}

export const validateEmail = ( email: string ): boolean => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test( String( email ).toLowerCase() );
}

export const validatePassword = ( password: string ): boolean => {
	const re =
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}:;<>,.?~_+\-|=]).{10,20}$/
	return re.test( String( password ));
}

export const validateMfaCode = ( code: string ): boolean => {
    const re = /^\d{6}$/;
    return re.test( code );
}

export const fileSizeConverter = ( bytes: number, unit?: 'B' | 'KB' | 'MB' | 'GB' | 'TB' ) => {
    if (!bytes || bytes <= 0) return '0 Bytes';

    let sizeInUnit: number;
    let suffix: string;
    let units: Array< string > = [ 'Bytes', 'KB', 'MB', 'GB', 'TB' ];
    
    if ( unit ) {
        switch ( unit ) {
            case 'B':
                return `${bytes} Bytes`;
            case 'KB':
                sizeInUnit = bytes / 1024;
                suffix = 'KB';
                break;
            case 'MB':
                sizeInUnit = bytes / Math.pow(1024, 2);
                suffix = 'MB';
                break;
            case 'GB':
                sizeInUnit = bytes / Math.pow(1024, 3);
                suffix = 'GB';
                break;
            case 'TB':
                sizeInUnit = bytes / Math.pow(1024, 4);
                suffix = 'TB';
                break;
        }
    } else {
        // Return appropriate unit for file size
        let i = 0;
        while (bytes >= 1024 && i < units.length - 1) {
            bytes /= 1024;
            i++;
        }
        suffix = units[ i ];
        sizeInUnit = bytes;
    }
    // Round up the file size to the nearest whole number and return it along with its unit of measurement
    const convertedValue = `${ Math.ceil( sizeInUnit ) } ${ suffix }`;
    return convertedValue;
}


interface sortedClaims { historical: Array< IReimbHistoryItem >, active: Array< IReimbHistoryItem > }

export function catagorizeClaims( claims: Array< IReimbHistoryItem > ): sortedClaims {
    let now = new Date().toISOString();

    let ActiveClaims = claims.filter( claim => claim.StatusID === ReimbStatus.PENDING || claim.StatusID === ReimbStatus.ON_HOLD );
    let FinalizedPast30Days = claims.filter( claim => daysBetweenDates( claim.LastActionDate, now ) < 30 && ( 
        claim.StatusID === ReimbStatus.PAID || claim.StatusID === ReimbStatus.DECLINED 
    ));

    let filteredOut = claims.filter(claim => !ActiveClaims.includes( claim ) && !FinalizedPast30Days.includes( claim ));

    let sortedClaims = {
        historical: filteredOut,
        active: [ ...ActiveClaims, ...FinalizedPast30Days ]
    }

    return sortedClaims;
}

export function switchAccType( type: number ): string {
    switch ( type ) {
        case BankAccountType.SYS_RE:
            return 'System Reimbursement';
        case BankAccountType.EMPLOYER_PAYROLL_CLEARING:
            return 'Employer Payroll Clearing';
        case BankAccountType.EMPLOYEE_OPERATING:
            return 'Employee Operating';
        case BankAccountType.EMPLOYER_GST_HOLD:
            return 'Employer GST Hold';
        case BankAccountType.EMPLOYEE_FBT_HOLDINGS:
            return 'Employee FBT Holdings';
        case BankAccountType.SUPPLIER_CLEARING:
            return 'Supplier Clearing';
        case BankAccountType.EMPLOYEE_LUXNONDED_HOLDINGS:
            return 'Employee Luxury Non-Deductable Holdings';
        case BankAccountType.EMPLOYER_BANK_ACCOUNT:
            return 'Employer Bank Account';
        case BankAccountType.TRACKING_EMPLOYEE_PAYROLL:
            return 'Tracking Employee Payroll';
        case BankAccountType.COMPANY_CREDIT_CARD:
            return 'Company Credit Card';
        case BankAccountType.EMPLOYEE_PERSONAL_ACCOUNT:
            return 'Personal Account';
        case BankAccountType.EMPLOYER_UNDER_OVERS:
            return 'Employer Under/Overs';
        case BankAccountType.EMPLOYER_OPERATIONAL_ACCOUNT:
            return 'Employer Operational Account';
        case BankAccountType.EMPLOYER_COMPANY_ACCOUNT:
            return 'Employer Company Account';
        case BankAccountType.COMPANY_FBT_HOLDING:
            return 'Company FBT Holding';
        case BankAccountType.COMPANY_LUXNONDED_HOLDING:
            return 'Company Luxury Non-Deductable Holding';
        case BankAccountType.AGENCY_FLOAT:
            return 'Agency Float';
        case BankAccountType.EMPLOYER_FBT_SUSPENSE:
            return 'Employer FBT Suspense';
        case BankAccountType.EMPLOYER_LND_SUSPENSE:
            return 'Employer LND Suspense';
        case BankAccountType.ABA_TRACE_ACCOUNT:
            return 'ABA Trace Account';
        case BankAccountType.OTHER_SALPACK_PROVIDER:
            return 'Other Salary Packaging Provider';
        default:
            return 'Unknown Account Type';
    }
}

export function switchBankAccApprovalStatus( status: number ): string {
    switch ( status ) {
        case BankAccApprovalStatus.DECLINED:
            return 'Declined';
        case BankAccApprovalStatus.PENDING_APPROVAL:
            return 'Pending Approval';
        case BankAccApprovalStatus.PARTIALLY_APPROVED:
            return 'Partially Approved';
        case BankAccApprovalStatus.FULLY_APPROVED:
            return 'Fully Approved';
        case BankAccApprovalStatus.APPROVAL_NOT_REQUIRED:
            return 'Approval Not Required'
        case BankAccApprovalStatus.ACCOUNT_DELETED:
            return 'Deleted';
        default:
            return 'Unknown Approval Status';
    }
}



