import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [ `&.${ linearProgressClasses.colorPrimary }` ]: {
        backgroundColor: theme.palette.grey[ 200 ],
        ...theme.applyStyles( 'dark', {
            backgroundColor: theme.palette.grey[ 800 ]
        })
    },
    [ `& .${ linearProgressClasses.bar }` ]: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
        ...theme.applyStyles( 'dark', {
            backgroundColor: '#308fe8',
        }),
    },
}));


interface LinearProgressGaugeProps {
    value: number;
}
export const LinearProgressGauge = ({ value }: LinearProgressGaugeProps ) => (
    <Box sx={{ flexGrow: 1 }}>
        { value }% used
        <BorderLinearProgress variant="determinate" value={ value } />
    </Box>
)