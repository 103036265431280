import { AppDispatch } from '../../redux/Store';
import { Middleware, AsyncThunkAction } from "@reduxjs/toolkit";
import { initiateStepUpAuth, initiateStepUpProcess, resetStepUpAuth } from '../../redux/slicers/StepUpAuth';
import { AppStatusCodes, MFA_Reason, Action } from '../../constants/enums';
import { IHeldAction } from '../../constants/interfaces';
import { DateTime } from 'luxon';
import { addAlert } from '../../redux/slicers/AppState';
import {
    addNewAddress,
    addNewBankAccount,
    updatePhone,
    updateEmail,
    updateAddress,
    updatePreferences,
    deletePhone,
    deleteEmail,
    deleteBankAccount
} from '../../redux/AccessThunks';

// Contact Method Change Actions. These will need the code to be sent to the new contact method
const CCM = [ Action.ADD_NEW_PHONE, Action.ADD_NEW_EMAIL ]

const arrayActions = [
    {
        actionType: Action.ADD_NEW_ADDRESS,
        action: ( data: any ) => addNewAddress( data )
    },
    {
        actionType: Action.ADD_NEW_BANK_ACCOUNT,
        action: ( data: any ) => addNewBankAccount( data )
    },
    {
        actionType: Action.UPDATE_PHONE,
        action: ( data: any ) => updatePhone( data )
    },
    {
        actionType: Action.UPDATE_EMAIL,
        action: ( data: any ) => updateEmail( data )
    },
    {
        actionType: Action.UPDATE_ADDRESS,
        action: ( data: any ) => updateAddress( data )
    },
    {
        actionType: Action.UPDATE_PREFERENCES,
        action: ( data: any ) => updatePreferences( data )
    },
    {
        actionType: Action.DELETE_PHONE,
        action: ( data: any ) => deletePhone( data )
    },
    {
        actionType: Action.DELETE_EMAIL,
        action: ( data: any ) => deleteEmail( data )
    },
    {
        actionType: Action.DELETE_BANK_ACCOUNT,
        action: ( data: any ) => deleteBankAccount( data )
    }
]

export async function actionInterceptor( 
    action: Action,
    data: any,
    thunk: any
): Promise< 'Proceed' | 'Hold' | 'Abort' > {
    let currentDate = DateTime.utc();
    let expiryDate = DateTime.fromISO( thunk.getState().StepUpAuth.stepUpExpiry );
    const stepUpApproved = thunk.getState().StepUpAuth.stepUpApproved === true;
    const isExpired = currentDate > expiryDate;
    let isHighRisk = arrayActions.find( a => a.actionType === action ) ? true : false;
    
    if ( !isHighRisk || isHighRisk && stepUpApproved && !isExpired ) return 'Proceed';

    let reason: MFA_Reason = MFA_Reason.SENSITIVE_INFO_CHANGE;
    const { code } = await thunk.dispatch( initiateStepUpAuth( reason ) ).unwrap();
    if ( code === AppStatusCodes.MFA_REQUIRED ) {
        let heldAction: IHeldAction = { action, data }
        thunk.dispatch( initiateStepUpProcess( heldAction ))
        thunk.dispatch( addAlert({ id: Date.now(), message: '6 digit MFA code required', type: 'info' }) )
        return 'Hold';
    }
    thunk.dispatch( resetStepUpAuth() )
    return 'Abort';
}

export async function actionInterpreter( dispatch: AppDispatch, heldAction: IHeldAction ) {
    let action: Action = heldAction.action;
    let data: any = heldAction.data;
    let thunk = arrayActions.find( a => a.actionType === action )?.action( data ) as AsyncThunkAction< any, any, any >;
    return await dispatch( thunk ).unwrap();
}

export const LoggingMiddleware: Middleware = store => next => action => {
    if ( process.env.NODE_ENV !== 'development' ) return next( action );
    console.log( 'LoggingMiddleware', action );
    return next( action );
}