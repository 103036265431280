import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { IUserPhone } from '../../../constants/interfaces';
import { PhoneType } from '../../../constants/enums';
import { addNewPhone } from '../../../redux/AccessThunks';
import { AppDispatch } from '../../../redux/Store';
import { BasicDropDownMenu, StandardTextInput } from '../../../components/UI';
import { phoneTypeIDConverter } from '../../../components/commonUIF';
import { Dialog_DetailEdit } from '../../../components/UI';


interface AddPhoneDialogProps {
    show: boolean;
    close: () => void;
    contactID: number;
    dispatch: AppDispatch;
}

export const AddPhoneDialog = ({ ...props }: AddPhoneDialogProps ) =>  {
    const { show, close, contactID, dispatch } = props
    const [ newPhone, setNewPhone ] = useState< IUserPhone >({
        ContactID: contactID,
        PhoneID: 0,
        PhoneTypeID: PhoneType.MOBILE,
        PhoneType: '',
        PhoneNumber: ''
    })

    const onCancel = () => {
        setNewPhone({
            ContactID: contactID,
            PhoneID: 0,
            PhoneTypeID: PhoneType.MOBILE,
            PhoneType: '',
            PhoneNumber: ''
        })
        close()
    }

    const onAddPhone = async () => {

        // Validation Logic Required \\
        if ( newPhone.PhoneNumber === '' ) {
            return alert( 'Phone Number is required' )
        }
        if ( newPhone.PhoneNumber.length < 10 ) {
            return alert( 'Phone Number must be at least 10 digits' )
        }
        if ( newPhone.PhoneNumber.length > 10 ) {
            return alert( 'Phone Number must be no more than 10 digits' )
        }
        if ( PhoneType[ newPhone.PhoneTypeID ] === '' ) {
            return alert( 'Phone Type is required' )
        }

        await dispatch( addNewPhone( newPhone ))
        setNewPhone({
            ContactID: 0,
            PhoneID: 0,
            PhoneTypeID: PhoneType.MOBILE,
            PhoneType: '',
            PhoneNumber: ''
        })
        close()
    }

    return (
        <Dialog_DetailEdit
            show={ show }
            title='Add Phone Number'
            confirmLabel='Add'
            cancelLabel='Cancel'
            slideDirection='up'
            onConfirm={ onAddPhone }
            onCancel={ onCancel }
        >
            <form>
                <Grid
                    container
                    direction='column'
                    spacing={ 1 }
                >
                    <Grid item>
                        <BasicDropDownMenu
                            menuLabel='Phone Type'
                            selectID='phoneType'
                            size='small'
                            options={[
                                { key: 1, label: 'Mobile', value: PhoneType.MOBILE },
                                { key: 2, label: 'Home', value: PhoneType.HOME },
                                { key: 3, label: 'Work', value: PhoneType.BUSINESS },
                                { key: 4, label: 'Business Direct', value: PhoneType.BIZ_DIRECT },
                                { key: 5, label: 'Fax', value: PhoneType.FAX }
                            ]}
                            onChange={ ( v ) => setNewPhone({ ...newPhone, PhoneTypeID: v, PhoneType: phoneTypeIDConverter( v )  }) }
                        />
                    </Grid>

                    <Grid item>
                        <StandardTextInput
                            variant='standard'
                            size='small'
                            label='New Phone'
                            value={ newPhone.PhoneNumber }
                            type='text'
                            onChange={ ( v ) => setNewPhone({ ...newPhone, PhoneNumber: v }) }
                        />
                    </Grid>
                </Grid>
            </form>
        </Dialog_DetailEdit>
    )
}