import { 
    styled,
    Box,
    Container,
    Grid,
    AppBar,
    Toolbar
} from '@mui/material';

/* Layout */
export const RootContainer = styled( Box )(({ theme }) => ({
    flexGrow: 1,
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.default,
    // '&::-webkit-scrollbar': {
    //     width: '0.3rem',
    // },
    // '&::-webkit-scrollbar-track': {
    //     backgroundColor: "#000",
    // },
    // '&::-webkit-scrollbar-thumb': {
    //     backgroundColor: theme.palette.divider,
    //     borderRadius: '0.5rem',
    // },
}))

export const PreAuthOuterContainer = styled( Box )(({ theme }) => ({
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'row',
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.backgroundOverlay : '',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none'
}))

export const PreAuthStyledGrid = styled( Box )(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    '@media (max-width: 840px)': {
        flexDirection: 'column',
        height: '100vh'
    }
}))

export const BrandingBox = styled( Box )(({ theme }) => ({
    boxSizing: 'border-box',
    height: '100vh',
    width: '65vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    padding: 0,
    backgroundColor: theme.palette.primary.main,
    boxShadow: '10px 0 20px -5px rgba(76, 127, 230, 0.7)',
    transition: 'height 0.4s ease, width 0.4s ease',
    //marginLeft: '0',
    '@media (max-width: 840px)': {
        width: '100vw',
        height: '25vh'
    }
}))


export const LogoContainer = styled( Box )(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    //position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0
}))

export const FormContainer = styled( Box )(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '35vw',
    height: '100vh',
    zIndex: 1,
    transition: 'width 0.4s ease, height 0.4s ease',
    '@media (max-width: 840px)': {
        width: '100vw',
        height: '75vh'
    }
}))

export const SymbolContainer = styled( Box )(({ theme }) => ({
    display: 'flex',
    width: '100%',
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    right: -165,
    overflow: 'hidden',
    transition: 'opacity 0.4s ease',
    '@media (max-width: 840px)': {
        opacity: 0
    }
}))

export const FormLogoContainer = styled( Box )(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: 'opacity 0.4s ease, height 0.4s ease',
    position: 'relative',  // Ensure it overlays correctly
    top: 0,  // Adjust positioning as necessary
    left: 0,  // Adjust positioning as necessary
    width: '100%',  // Ensure it covers the same area
    height: '0%',  // Ensure it covers the same area
    '@media (max-width: 840px)': {
        height: 'auto',
        opacity: 1
    }
}))


export const OuterContainer = styled( Box )(({ theme }) => ({
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'row',
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.backgroundOverlay : '',
    border: 'none',
    boxSizing: 'border-box',
}))

export const InnerContainer = styled( Box, { shouldForwardProp: ( prop ) => prop !== 'navmenu' })<{ navmenu: boolean }>(({ theme, navmenu }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    border: 'none',
    marginTop: '0.75rem',
    marginRight: '0.75rem',
    marginBottom: '0.75rem',
    marginLeft: '0.75rem',
    boxSizing: 'border-box'
}))

export const StyledMain = styled( Container )(({ theme }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '0%',
    paddingBottom: '0.4rem',
    paddingLeft: '0.8rem',
    paddingRight: '0.8rem',
    position: 'relative',
    border: 'none',
    height: '100%'
}))

export const StyledGrid = styled( Grid )(({ theme }) => ({
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'inherit',
    flex: 'inherit',
    overflow: 'inherit',
    position: 'relative'
}))

export const Footer = styled( Box )(({ theme }) => ({
    boxSizing: 'border-box',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row', 
    justifyContent: "flex-start",
    alignItems: "center",
    height: '4.5vh',
    elevation: 0,
    borderRadius: '0.4rem',
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    border: `none`,
    "& > *": {
        transition: theme.transitions.create( "all", {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.standard,
        }),
    }
}))

export const PreAuthFooter = styled( Box )(({ theme }) => ({
    boxSizing: 'border-box',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "flex-start",
    alignItems: "center",
    height: '5vh',
    elevation: 0,
    borderRadius: '0.4rem',
    backgroundColor: 'transparent',
    paddingLeft: '1vw',
    paddingRight: '1vw',
    border: `none`,
    color: theme.palette.common.white,
    zIndex: 1000
}))

export const NavBar = styled( AppBar )(({ theme }) => ({
    display: 'grid',
    flexDirection: 'column',
    width: '100%',
    boxShadow: 'none',
    borderRadius: '0.4rem',
    position: 'sticky',
    alignItems: 'center',
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    margin: 0,
    elevation: 0,
    height: '4.5vh',
    "& > *": {
        transition: theme.transitions.create( "all", {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.standard,
        }),
    }
}))

export const NavToolbar = styled( Toolbar )(({ theme }) => ({
    height: 'inherit',
    minHeight: 'inherit',
    color: 'inherit',
    backgroundColor: 'transparent'
}))