import {
    styled,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
} from '@mui/material';

// Below components are used in the Dialog_DetailEdit component \\
export const DEContainer = styled( Dialog )(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '0.5rem',
        padding: 0,
        margin: 0,
        minWidth: '350px',
        maxWidth: '500px',
        backgroundColor: theme.palette.background.paper
    }
}));

export const DEContent = styled( DialogContent )(({ theme }) => ({
    padding: '0.5rem 1rem'
}));

export const DETitle = styled( DialogTitle )(({ theme }) => ({
    padding: '0.5rem 1rem',
}));

export const DEActions = styled( DialogActions )(({ theme }) => ({

}));

export const DEContentText = styled( DialogContentText )(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.primary
}));

export const DESlide = styled( Slide )(({ theme }) => ({

}));