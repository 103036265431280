import { ILeaseAccDetailOverview, IObjectKeyValues } from "src/constants/interfaces"
import { currencyFormatter } from "../../components/commonUIF"

export function financialData( leaseAccountDetail: ILeaseAccDetailOverview ): { accountInfo: IObjectKeyValues, balanceInfo: IObjectKeyValues } {

    const info: IObjectKeyValues = {
        accountHolder: {
            key: 'FullName',
            label: 'Account Holder',
            value: leaseAccountDetail?.FullName ? leaseAccountDetail.FullName : '(No Data)',
            isEdit: false
        },
        linkedPackage: {
            key: 'BenefitID',
            label: 'Package ID',
            value: leaseAccountDetail?.BenefitID ? leaseAccountDetail.BenefitID : '(No Data)',
            isEdit: false
        },
        employer: {
            key: 'Employer',
            label: 'Employer',
            value: leaseAccountDetail?.Employer ? leaseAccountDetail.Employer : '(No Data)',
            isEdit: false
        },
        fbtExempt: {
            key: 'FBTExempt',
            label: 'FBT Exempt',
            value: leaseAccountDetail?.FBTExempt ? 'Yes' : 'No',
            isEdit: false
        },
        fbtHeldByIE: {
            key: 'FBTHeldByAspire',
            label: 'FBT Held By InsideEDGE',
            value: leaseAccountDetail?.FBTHeldByAspire ? 'Yes' : 'No',
            isEdit: false
        },
        totalDaysVehicleHeldYTD: {
            key: 'DaysHeldYTD',
            label: 'Total Days Vehicle Held YTD',
            value: leaseAccountDetail?.DaysHeldYTD ? leaseAccountDetail.DaysHeldYTD : '(No Data)',
            isEdit: false
        },
        totalDaysUnavailableYTD: {
            key: 'DaysUnavailableYTD',
            label: 'Total Days Unavailable YTD',
            value: leaseAccountDetail?.DaysUnavailableYTD ? leaseAccountDetail.DaysUnavailableYTD : '(None)',
            isEdit: false
        }
    }

    const bal: IObjectKeyValues = {
        balanceIsCredit: {
            key: 'BalanceIsCredit',
            label: 'Balance is Credit',
            value: leaseAccountDetail?.AvailableBalance && leaseAccountDetail?.AvailableBalance > 0 ? true : false,
            isEdit: false
        },
        availableBalance: {
            key: 'AvailableBalance',
            label: 'Available Balance',
            value: leaseAccountDetail?.AvailableBalance || leaseAccountDetail?.AvailableBalance === 0 ? currencyFormatter.format( leaseAccountDetail.AvailableBalance ) : '(No Data)',
            isEdit: false
        },
        currentBalance: {
            key: 'FBTLiabilityYTD',
            label: 'FBT Liability YTD',
            value: leaseAccountDetail?.CurrentBalance || leaseAccountDetail?.CurrentBalance === 0 ? currencyFormatter.format( leaseAccountDetail.CurrentBalance ) : '(No Data)',
            isEdit: false
        },
        pendingTransactions: {
            key: 'PendingTransactions',
            label: 'Pending Transactions',
            value: leaseAccountDetail?.PendingTransactions || leaseAccountDetail?.PendingTransactions === 0 ? currencyFormatter.format( leaseAccountDetail.PendingTransactions ) : '(No Data)',
            isEdit: false
        },
        fbtLiabilityYTD: {
            key: 'FBTLiabilityYTD',
            label: 'FBT Liability YTD',
            value: leaseAccountDetail?.IndictiveFBT_YTD || leaseAccountDetail?.IndictiveFBT_YTD === 0 ? currencyFormatter.format( leaseAccountDetail.IndictiveFBT_YTD ) : '(No Data)',
            isEdit: false
        },
        lnd: {
            key: 'LND',
            label: 'Luxury Car Tax (LND)',
            value: leaseAccountDetail?.TotalOwedLND || leaseAccountDetail?.TotalOwedLND === 0  ? currencyFormatter.format( leaseAccountDetail.TotalOwedLND ) : '(No Data)',
            isEdit: false
        },
        finalBalance: {
            key: 'FinalBalance',
            label: 'Final Balance',
            value: ( leaseAccountDetail?.CurrentBalance || leaseAccountDetail?.TotalOwedLND === 0 ) 
                && ( leaseAccountDetail?.PendingTransactions || leaseAccountDetail?.PendingTransactions === 0 )
                && ( leaseAccountDetail?.IndictiveFBT_YTD || leaseAccountDetail?.IndictiveFBT_YTD === 0 ) 
                && ( leaseAccountDetail?.TotalOwedLND || leaseAccountDetail?.TotalOwedLND === 0 ) 
                ? currencyFormatter.format( leaseAccountDetail.CurrentBalance - ( leaseAccountDetail.PendingTransactions + leaseAccountDetail.IndictiveFBT_YTD + leaseAccountDetail.TotalOwedLND ))
                : '(No Data)',
            isEdit: false
        }
    }

    return { accountInfo: info, balanceInfo: bal }
}