import React, { useState, useEffect } from "react";
import { login, loginMFA } from "../../redux/AccessThunks";
import { addAlert } from "../../redux/slicers/AppState";
import { useAppDispatch, useAppSelector } from "../../redux/Store";
import { validateEmail } from "../../utils/commons";
import { useNavigate, useLocation } from "react-router-dom";
import { RoutePaths, AppStatusCodes, ErrorCodes } from "../../constants/enums";
import { LoginForm } from "./LoginForm";

export default function Login() {
    const { Authenticated } = useAppSelector(( state ) => state.Access )
    // Login vars
    const [ username, setUsername ] = useState< string >( '' )
    const [ password, setPassword ] = useState< string >( '' )
    // Login error handling vars
    const [ usernameError, setUsernameError ] = useState< boolean >( false )
    const [ passwordError, setPasswordError ] = useState< boolean >( false )
    const [ helperText, setHelperText ] = useState< string >( '' )
    // MFA vars
    const [ mfaResponseCode, setMfaResponseCode ] = useState< number >( 0 )
    const [ mfaError, setMfaError ] = useState< boolean >( false )
    const [ mfaHelpText, setMfaHelpText ] = useState< string >( '' )
    // Stepper & view management vars
    const [ activeStep, setActiveStep ] = useState< number >( 0 )
    const [ view, setView ] = useState< 'credentials' | 'mfa' | 'complete' >( 'credentials' )
    // Password reset vars
    const [ resetPassword, setResetPassword ] = useState< boolean >( false )
    const [ resetPasswordError, setResetPasswordError ] = useState< boolean >( false )
    // Redux & Router
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if ( Authenticated ) {
            navigate( RoutePaths.AUTHENTICATED_ROOT, { state: { from: location.pathname }, replace: true } )
        }
    }, [])
    
    const handleLogin = async ( username: string, password: string ) => {
        if ( Authenticated ) {
            navigate( RoutePaths.AUTHENTICATED_ROOT, { state: { from: location.pathname }, replace: true } )
            return
        }
        setHelperText( '' )
        setUsernameError( false )
        setPasswordError( false )

        if ( 
            !validateEmail( username )
        ) {
            setUsernameError( true )
            setHelperText( 'Invalid Email Address or Password. Please Try Again' )
            return
        }

        if (
            password === ''
            && password.length < 10
            && password.length > 20
            && password !== null
            && password !== undefined
        ) {
            setPasswordError( true )
            setHelperText( 'Invalid Email Address or Password. Please Try Again' )
            return
        }

        const response = await dispatch( login({ email: username, password: password })).unwrap()
        let { success, code, message } = response

        if ( success && code === AppStatusCodes.MFA_REQUIRED ) {
            setActiveStep( 1 )
            setView( 'mfa' )
            resetState( 'password' )
        }
        if ( !success && code === AppStatusCodes.FAILED_PASSWORD ) {
            setUsername( '' )
            setPassword( '' )
            setUsernameError( true )
            setPasswordError( true )
            setHelperText( 'Invalid Email Address or Password. Please Try Again' )
        }
        if ( !success && code === AppStatusCodes.USER_NOT_FOUND ) {
            setUsername( '' )
            setPassword( '' )
            setUsernameError( true )
            setPasswordError( true )
            setHelperText( 'Invalid Email Address or Password. Please Try Again' )
        }
        if ( !success && code === AppStatusCodes.USER_LOCKED_OUT ) {
            setUsername( '' )
            setPassword( '' )
            setUsernameError( true )
            setPasswordError( true )
            setHelperText( 'Your account has been locked. Please contact support for assistance' )
        }
    }

    const startMFA = ( mfaCode: string ) => {
        resetState( 'mfa' )
        if ( mfaCode === '' || mfaCode.length !== 6 ) {
            setMfaError( true )
            setMfaHelpText( 'Invalid MFA Code. Please Try Again' )
            return
        }
        setActiveStep( 2 )
        setView( 'complete' )
        handleMFA( mfaCode )
    }
    
    const handleMFA = async ( mfaCode: string ) => {

        const response = await dispatch( loginMFA({ MFACode: mfaCode })).unwrap()
        let { success, code, message } = response

        if ( !success && code === AppStatusCodes.MFA_INVALID_CODE ) {
            setMfaHelpText( 'The code you entered was invalid or incorrect. Please Try Again' )
            setMfaResponseCode( AppStatusCodes.MFA_INVALID_CODE )
            // Timeout used to allow the icon animations to finish before transitioning back to the MFA view
            setTimeout( () => {
                setMfaResponseCode( AppStatusCodes.MFA_INVALID_CODE )
                setActiveStep( 1 )
                setMfaError( true )
                setView( 'mfa' )
                dispatch( addAlert({ id: Date.now(), message: 'Invalid MFA Code. Please Try Again', type: 'error' }) )
            }, 2000 )
            return;
        }
        if ( success && code === AppStatusCodes.AUTHENTICATED ) {
            setActiveStep( 3 )
            setMfaResponseCode( AppStatusCodes.AUTHENTICATED )
            setView( 'complete' )
            // Timeout used to allow the icon animations to finish before redirecting
            setTimeout( () => {
                resetState( 'all' )
                navigate( RoutePaths.AUTHENTICATED_ROOT, { state: { from: location.pathname }, replace: true } )
                dispatch( addAlert({ id: Date.now(), message: 'Login Successful', type: 'success' }) )
            }, 3000 )
            return;
        }
    }

    const resetState = ( group: 'password' | 'mfa' | 'view' | 'all' ) => {
        if ( group === 'password' ) {
            setUsername( '' )
            setPassword( '' )
            setUsernameError( false )
            setPasswordError( false )
            setHelperText( '' )
        }
        if ( group === 'mfa' ) {
            setMfaResponseCode( 0 )
            setMfaError( false )
            setMfaHelpText( '' )
        }
        if ( group === 'view' ) {
            setActiveStep( 0 )
            setView( 'credentials' )
        }
        if ( group === 'all' ) {
            setUsername( '' )
            setPassword( '' )
            setUsernameError( false )
            setPasswordError( false )
            setHelperText( '' )
            setMfaResponseCode( 0 )
            setMfaError( false )
            setMfaHelpText( '' )
            setActiveStep( 0 )
            setView( 'credentials' )
        }
    }

    return (
        <LoginForm 
            username={ username }
            setUsername={ ( v ) => setUsername( v ) }
            password={ password }
            setPassword={ ( v ) => setPassword( v ) }
            usernameError={ usernameError }
            passwordError={ passwordError }
            mfaResponse={ mfaResponseCode }
            mfaError={ mfaError }
            mfaHelpText={ mfaHelpText }
            onLogin={ () => handleLogin( username, password ) }
            onResetPassword={ () => navigate( RoutePaths.PASSWORD_RESET, { state: { from: location.pathname }} ) }
            helpText={ helperText }
            currentView={ view }
            currentStep={ activeStep }
            onMfaCompleted={ ( v ) => startMFA( v ) }
        />
    )
}

