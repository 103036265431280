import React, { useState } from "react";
import { Paper, Grid, InputBase, Typography, Button, Divider } from "@mui/material";
import { NotificationsContent } from "../../components/styled";
import { useAppDispatch } from "../../redux/Store";

export default function Notifications() {
    const dispatch = useAppDispatch()
    

    return (
        <NotificationsContent>
            <Grid container justifyContent='center'>
                <Grid item>
                    <Paper sx={{ padding: 1 }}>
                        <Typography variant='h6'>Notification Center</Typography>
                    </Paper>
                </Grid>
            </Grid>
        </NotificationsContent>
    )
}