import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/Store';
import { resetStepUpAuth, verifyStepUpAuth, clearPendingAction, vidVerification } from '../../../redux/slicers/StepUpAuth';
import { toggleError } from '../../../redux/slicers/AppState';
import { Box, Typography, useTheme, FormHelperText } from '@mui/material';
import { BasicButton } from '../';
import { CancelIcon } from '../Icons';
import { DEContainer, DEContent, StepUpSlideContainer, DEActions } from '../../styled';
import { mfaMethodText } from '../../commonUIF';
import { AppStatusCodes, MFAMethod, PhoneType } from '../../../constants/enums';
import { SecurityIcon } from '../Icons';
import { StepUpInput } from './StepUpInput';
import { StepUpLoader } from './StepUpLoader';
import { actionInterpreter } from '../../../utils';
import { IHeldAction } from 'src/constants/interfaces';


// const Transition = ( slideDirection: 'up' | 'down' | 'left' | 'right' ) => React.forwardRef(function Transition(
//     props: TransitionProps & SlideProps & DialogProps & {
//         children: React.ReactElement<any, any>
//     },
//     ref: React.Ref<unknown>
//   ) {
//     return <Slide direction={ slideDirection } ref={ ref } { ...props } />;
// });
  
export function StepUpAuthentication() {
    const { showMfaInput, pendingAction, vidOnly } = useAppSelector(( state ) => state.StepUpAuth );
    const { userDetails } = useAppSelector( state => state.Access );
    const [ mfaCode, setMfaState ] = useState< string >( '' );
    const [ mfaError, setMfaError ] = useState< boolean >( false );
    const [ view, setView ] = useState< 'code' | 'loading' >( 'code' );
    const [ responseCode, setResponseCode ] = useState< number >( 0 );
    const [ helpText, setHelpText ] = useState< string >( '' );
    const dispatch = useAppDispatch();
    let isDarkMode = useTheme().palette.mode === 'dark';
    let mfaMethod = mfaMethodText( userDetails?.Preferences.MfaMethod );

    // Resets the local state when the mfaCode is set back to ''
    useEffect(() => {
        if ( mfaCode.length === 6 && !vidOnly) {
            stepUpHandler( mfaCode );
            setMfaState( '' );
        }
        if ( mfaCode.length === 6 && vidOnly ) {
            vidHandler( mfaCode );
            setMfaState( '' );
        }
    }, [ mfaCode ])

    async function stepUpHandler( value: string ) {
        setView( 'loading' );
        let action = pendingAction as IHeldAction;
        
        const { code } = await dispatch( verifyStepUpAuth({ MFACode: value }) ).unwrap();
        switch ( code ) {
            case AppStatusCodes.MFA_INVALID_CODE:
                setResponseCode( code );
                setHelpText( 'The code you entered was invalid or incorrect. Please Try Again' );
                setTimeout( () => {
                    setResponseCode( 0 );
                    setMfaError( true );
                    setView( 'code' );
                }, 2000 );
                break;
            case AppStatusCodes.STEP_UP_APPROVED:
                setResponseCode( code );
                // Set timout to allow the animation to complete before dispatching the action
                setTimeout( () => { ( async () => {
                    await actionInterpreter( dispatch, action )
                    dispatch( clearPendingAction() );
                    resetState();
                })() }, 3000 );
                break;
            default:
                setResponseCode( code );
                resetState();
                dispatch( toggleError({
                    error: true,
                    errorMessage: 'MFA Verification Failed. Please try later.',
                    errorCode: code
                }))
            ;
        }
    }

    async function vidHandler( value: string ) {
        setView( 'loading' );
        const { code } = await dispatch( vidVerification( value ) ).unwrap();
        switch ( code ) {
            case AppStatusCodes.MFA_INVALID_CODE:
                setResponseCode( code );
                setHelpText( 'The code you entered was invalid or incorrect. Please Try Again' );
                setTimeout( () => {
                    setResponseCode( 0 );
                    setMfaError( true );
                    setView( 'code' );
                }, 2000 );
                break;
            case AppStatusCodes.SUCCESS:
                setResponseCode( code );
                // Set timout to allow the animation to complete before dispatching the action
                setTimeout( () => { ( async () => {
                    dispatch( resetStepUpAuth() );
                    resetState();
                })() }, 3000 );
                break;
            default:
                setResponseCode( code );
                resetState();
                dispatch( toggleError({
                    error: true,
                    errorMessage: 'MFA Verification Failed. Please try later.',
                    errorCode: code
                }));
            ;
        }
    }

    const selectHandler = ( value: string ) => {
        if ( vidOnly ) {
            vidHandler( value );
        } else {
            stepUpHandler( value );
        }
    }

    function onCancel() {
        setMfaState( '' );
        dispatch( resetStepUpAuth() );
    }

    function resetState() {
        setMfaState( '' );
        setMfaError( false );
        setHelpText( '' );
        setView( 'code' );
    }

    return (
        <DEContainer
            open={ showMfaInput }
            disableEscapeKeyDown
            slotProps={{ backdrop: { onClick: ( e ) => e.stopPropagation() } }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '1rem',
                }}
            >
                <SecurityIcon outlined={ isDarkMode } color='warning' size='large' />&nbsp;
                <Typography variant="h5">
                    Sensitive Information Change
                </Typography> 
            </Box>
            <DEContent sx={{ width: '100%' }}>

                {
                    vidOnly !== false && (
                        <Typography variant='body1'>
                            We have sent a 6 digit code to the new { vidOnly === 'PHV' ? 'phone number' : 'email address' }.<br />
                            Please verify the new { vidOnly === 'PHV' ? 'phone number' : 'email address' } by entering the 6 digit code we have sent.
                        </Typography>
                    )
                }
                {
                    vidOnly === false && (
                        <Typography variant='body1'>
                            Please confirm your identity by entering the MFA code sent to your { mfaMethod }.
                        </Typography>
                    )
                }

                <StepUpSlideContainer>

                    <StepUpInput
                        length={ 6 }
                        error={ mfaError }
                        view={ view }
                        onCompletion={ ( v ) => setMfaState( v ) }
                        helpText={ helpText }
                        onCancel={ onCancel }
                    />
                
                    <StepUpLoader
                        minMilliSeconds={ 1000 }
                        mfaResponse={ responseCode }
                        currentView={ view }
                    />

                </StepUpSlideContainer>

            </DEContent>

            <DEActions sx={{ marginTop: '-1rem' }}>
   
                <Box sx={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <BasicButton
                        disabled={ view === 'loading' }
                        onClickHandler={ onCancel }  
                        text={ "Cancel" }
                        iconPosition='end'
                        icon={ <CancelIcon outlined={ isDarkMode } color='warning' size='small' /> }
                        size='small'
                    />
                </Box>
                <Box sx={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <BasicButton
                        disabled={ view === 'loading' }
                        onClickHandler={ () => selectHandler( mfaCode ) }
                        text={ "Submit" }
                        size='small'
                    />
                </Box>

            </DEActions>
        </DEContainer>
    );
}