import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../redux/Store';
import { PreAuthBaseFooter } from './PreAuthFooter';
import { PreAuthMainGrid } from './PreAuthMain';
import { RootContainer, PreAuthOuterContainer } from '../styled';
import { SystemDialog, AppSnackbar } from '../UI';


export function PreAuthLayout() {
    const { appName, version } = useAppSelector(( state ) => state.AppState )

    return (
        <RootContainer className='rootContainer'> 
            <Helmet>
                    <meta charSet='utf-8' />
                    <meta name='viewport' content='width=device-width, initial-scale=1' />
                    <title>InisdeEDGE - AutoDash</title>
                    <link rel='preconnect' href='https://fonts.googleapis.com' />
                    <link rel='stylesheet' href='https://fonts.googleapis.com/icon?family=Material+Icons' />
            </Helmet>

            <PreAuthOuterContainer className='outerContainer'>
                    
                <PreAuthMainGrid>
                    <Outlet />
                </PreAuthMainGrid>
                
                <PreAuthBaseFooter
                    appName={ appName }
                    version={ version }
                />

            </PreAuthOuterContainer>
            
            <SystemDialog />
            <AppSnackbar />
        </RootContainer>
    )
}