import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { operCostIDConverter } from '../../../../components/commonUIF';
import { IReimbursmentSubmission } from 'src/constants/interfaces';
import { ReimbOperCostType } from '../../../../constants/enums';
import { useAppDispatch, useAppSelector } from '../../../../redux/Store';
import { columnBase, OperatingCostSelectOptions } from '../../../../constants';
import { submitNewReimbursement, fetchBankAccounts } from '../../../../redux/AccessThunks';
import { currentDate } from '../../../../utils/commons';
import { ReimbursementForm } from './ReimbursementForm';
import { validateSubmission } from './SubmissionValidation';
import { SendIcon } from '../../../../components/UI/Icons';
import {
    DataGroup,
    BasicSelectOption,
    FileUploader
} from '../../../../components/UI';

const defaultValues: IReimbursmentSubmission = {
    ContactID: 0,
    SubmittedByID: 0,
    BenefitID: 0,
    BenefitDesc: '',
    Odometer: 0,
    BenefitOperCostTypeID: 0,
    BenefitOperCostName: '',
    PurchaseDate: '',
    SupplierCompanyName: '',
    IsNoReferenceNumber: false,
    SupplierRefText: '',
    AmountIncTax: '0',
    AmountTax: '0',
    UserComment: '',
    BankAccountID: 0,
    IsGSTOverride: false,
    FileAttachments: []
}

export function SubmitNewReimbursement() {
    const { userDetails, registeredBankAccounts, basicPackageDetail } = useAppSelector(( state ) => state.Access );
    const [ bankAccountOptions, setBankAccountOptions ] = useState< Array< BasicSelectOption > >([]);
    const [ submissionSuccess, setSubmissionSuccess ] = useState< boolean >(  );
    const [ formHeight, setFormHeight ] = useState< number >( 0 );
    const [ reimbursementValues, setReimbursementValues ] = useState< IReimbursmentSubmission >( defaultValues );
    const dispatch = useAppDispatch();
    const isDark: boolean = useTheme().palette.mode === 'dark';

    useEffect(() => {
        if ( registeredBankAccounts.length === 0 && userDetails.ContactID !== 0 ) {
            ( async () => await dispatch( fetchBankAccounts({ ContactID: userDetails.ContactID, ActiveOnly: 1 }) ))();
        }
    }, []);

    useEffect(() => {
        if ( registeredBankAccounts.length > 0 ) {
            let bankOptions: Array< BasicSelectOption > = [];
            registeredBankAccounts.forEach(( acc ) => {
                bankOptions.push({ 
                    key: acc.AccID,
                    label: `${ acc.AccNickname ? acc.AccNickname : acc.AccFormalName } - BSB: ${ acc.AccBSB } Acc: ${ acc.AccNumber }`,
                    value: acc.AccID 
                });
            });
            setBankAccountOptions( bankOptions );
        }
    }, [ registeredBankAccounts ]);


    // remove ended packages
    const filterPackages = basicPackageDetail.filter(( veh ) => veh.BenefitStatus !== 'Ended' );

    // Map object for package selection
    const packages = filterPackages.map(( veh ) => (
        {
            key: `${ veh.BenefitID } - ${ veh.Rego }`,
            label: `${ veh.VehicleDescription } - (${ veh.BenefitStatus })`,
            value: veh.BenefitID
        }
    ))

    // Benefit Select and Description Setter 
    function handleBenefitSelection( value: any ) {
        let selectedBenefit = basicPackageDetail.filter(( benefit ) => benefit.BenefitID === value );
        setReimbursementValues({ 
            ...reimbursementValues,
            ContactID: userDetails.ContactID, 
            SubmittedByID: userDetails.ContactID, 
            BenefitID: value, 
            BenefitDesc: selectedBenefit[ 0 ].VehicleDescription 
        });
    }

    async function onSubmitReimbursement( reimbursement: IReimbursmentSubmission ) {
        // Validate the submission
        //const { isValid, errors } = validateSubmission( reimbursement );
        let isValid = true;
        if ( isValid ) {
            // Submit the reimbursement
            const formData: IReimbursmentSubmission = {
                ContactID: userDetails.ContactID,
                SubmittedByID: userDetails.ContactID,
                BenefitID: reimbursement.BenefitID,
                BenefitDesc: reimbursement.BenefitDesc,
                Odometer: reimbursement.Odometer,
                BenefitOperCostTypeID: reimbursement.BenefitOperCostTypeID,
                BenefitOperCostName: reimbursement.BenefitOperCostName,
                PurchaseDate: reimbursement.PurchaseDate,
                SupplierCompanyName: reimbursement.SupplierCompanyName,
                IsNoReferenceNumber: reimbursement.IsNoReferenceNumber,
                SupplierRefText: reimbursement.SupplierRefText,
                AmountIncTax: reimbursement.AmountIncTax,
                AmountTax: `${ reimbursement.IsGSTOverride ? parseInt( reimbursement.AmountIncTax, 10 ) / 11 : reimbursement.AmountTax }`,
                UserComment: reimbursement.UserComment,
                BankAccountID: reimbursement.BankAccountID,
                IsGSTOverride: reimbursement.IsGSTOverride,
                FileAttachments: [ ...reimbursement.FileAttachments ]
            }

            let submitted = await dispatch( submitNewReimbursement( formData ) ).unwrap();
            if ( submitted.success && typeof submitted.payload.NewSubmissionID === 'number' ) {
                let submissionRef = submitted.payload.NewSubmissionID;
                setReimbursementValues( defaultValues );
                setSubmissionSuccess( true );
                alert( `Reimbursement submitted successfully. Reference Number: ${ submissionRef }` );
            } else {
                alert( 'Reimbursement submission failed' );
            }
        } else {
            // Display the errors & handle validation errors
            //console.log( errors );
        }
    }

    const rv: IReimbursmentSubmission = reimbursementValues;
    const unlockForm: boolean = rv.BenefitID === 0 
        || rv.BenefitOperCostTypeID === 0 
        || rv.BankAccountID === 0
    ;
    const unlockSubmit: boolean = rv.BenefitID === 0 
        || rv.BenefitOperCostTypeID === 0 
        || rv.BankAccountID === 0
        || rv.SupplierCompanyName === ''
        || rv.PurchaseDate === ''
        || rv.AmountIncTax === '0'
        || !rv.AmountIncTax
        || rv.FileAttachments.length === 0
    ;

    const handlekeyPress = ( event: React.KeyboardEvent ) => {
        if ( event.key === 'Enter' && !unlockSubmit ) {
            onSubmitReimbursement( reimbursementValues );
        }
    }

    return (
        <Box className='submit-reimbursement-container'
            sx={{ maxWidth: '100%', minWidth: '100%' }}
        >
            <Grid container
                flexDirection='column'
                justifyContent='center' 
                columns={ columnBase }
                spacing={ 1 }
                p={ 1 }
                width='100%'
                sx={{ display: 'flex', flexDirection: 'row' }}
            >

                <ReimbursementForm
                    newReimbursement={ reimbursementValues }
                    packages={ packages }
                    selectedBenefit={ reimbursementValues.BenefitID }
                    selectedOperCost={ reimbursementValues.BenefitOperCostTypeID }
                    selectedAccount={ reimbursementValues.BankAccountID }
                    bankAccountOptions={ bankAccountOptions }
                    setReimbursementValues={ setReimbursementValues }
                    handleBenefitSelection={ handleBenefitSelection }
                    unlockForm={ unlockForm }
                    unlockSubmit={ unlockSubmit }
                    onKeyDown={ handlekeyPress }
                    mode={ isDark }
                    onSubmit={ () => onSubmitReimbursement( reimbursementValues ) }
                    xsmobile={ 4 }
                    mobile={ 5 }
                    tablet={ 8 }
                    laptop={ 5 }
                    desktop={ 6 }
                />

                <FileUploader
                    xsmobile={ 4 }
                    mobile={ 5 }
                    tablet={ 8 }
                    laptop={ 5 }
                    desktop={ 6 }
                    setFileUploads={ ( files ) => setReimbursementValues({ 
                        ...reimbursementValues, 
                        FileAttachments: files.map( file => ({
                            FileName: file.FileName,
                            FileData: file.FileData,
                            FileSize: file.FileSize,
                            FileType: file.FileType
                        })) 
                    })}
                    clearFiles={ submissionSuccess === true }
                />

            </Grid>
        </Box>
    )
}