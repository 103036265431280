import {
    Stepper,
    StepConnector,
    stepConnectorClasses,
    Typography,
    StepIconProps,
    Step,
    StepLabel,
    styled,
    Box,
    useTheme
} from "@mui/material";
import { tokens } from "../../theme";
import Check from '@mui/icons-material/Check';

interface StepperProps {
    steps: Array< string >;
    activeStep: number;
    viewport?: 'mobile' | 'desktop';
}

export function StepperBlue({ steps, activeStep, viewport }: StepperProps ) {
    const mode = useTheme().palette.mode;
    const colors = tokens( mode );
    
    const QontoConnector = styled( StepConnector )(({ theme }) => ({
        [ `&.${ stepConnectorClasses.alternativeLabel }` ]: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        [ `&.${ stepConnectorClasses.active }` ]: {
            [`& .${ stepConnectorClasses.line }`]: {
                borderColor: colors.vortex[ 500 ],
            },
        },
        [ `&.${ stepConnectorClasses.completed }` ]: {
            [ `& .${ stepConnectorClasses.line }` ]: {
                borderColor: colors.vortex[ 500 ],
            },
        },
        [ `& .${ stepConnectorClasses.line }` ]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : colors.skyCyan[ 200 ],
            borderTopWidth: 3,
            borderRadius: 1,
        },
    }));
        
    const QontoStepIconRoot = styled( 'div' )<{ ownerState: { active?: boolean | undefined } }>(
        ({ theme, ownerState }) => ({
            color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : colors.skyCyan[ 200 ],
            display: 'flex',
            height: 22,
            alignItems: 'center',
            ...( ownerState.active && {
                color: colors.vortex[ 500 ],
            }),
            '& .QontoStepIcon-completedIcon': {
                color: colors.vortex[ 500 ],
                zIndex: 1,
                fontSize: 18,
            },
            '& .QontoStepIcon-circle': {
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: 'currentColor',
            },
        }),
    );
    
    function QontoStepIcon( props: StepIconProps ) {
        const { active, completed, className } = props;
        
        return (
            <QontoStepIconRoot ownerState={{ active }} className={ className }>
            { completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
            </QontoStepIconRoot>
        );
    }
    
    
    return (
        <Box className='stepper-container'
            style={{
                alignContent: 'flex-start',
                minWidth: '100%',
                paddingTop: viewport === 'desktop' ? '4rem' : '0.5rem',
                boxSizing: 'border-box'
            }}
        >
            <Stepper alternativeLabel activeStep={ activeStep } connector={ <QontoConnector /> } style={{ width: '100%' }}>
                { steps.map(( label ) => (
                    <Step key={ label }>
                        <StepLabel StepIconComponent={ QontoStepIcon }>
                            <Typography variant="caption">{ label }</Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}