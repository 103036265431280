import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { ToolTipWrapper } from '../ToolTipWrapper';
import {
    DataCard,
    DataCardContent,
    DataCardLabel,
    DataCardValue,
    DataDivider
} from '../../styled';


interface DataFieldProps {
    className?: string;
    data: {
        key: string;
        label: string;
        value: any;
        isEdit: boolean;
        isCurrent?: boolean;
    };
    xsmobile?: number;
    mobile?: number;
    tablet?: number;
    laptop?: number;
    desktop?: number;
}

export function DataField({ data, ...props }: DataFieldProps ) {
    const { className, xsmobile, mobile, tablet, laptop, desktop } = props
    const [ isOverflowing, setIsOverflowing ] = useState( false );
    const valueRef = useRef< HTMLSpanElement >( null );

    useEffect(() => {
        const checkOverflow = () => {
            if ( valueRef.current ) {
                const isOverflowing = valueRef.current.offsetWidth < valueRef.current.scrollWidth;
                setIsOverflowing( isOverflowing );
            }
        };

        checkOverflow();

        // Recheck on window resize
        const handleResize = () => {
            checkOverflow();
        };

            window.addEventListener( 'resize', handleResize );
        return () => {
            window.removeEventListener( 'resize', handleResize );
        };
    }, [ data ]);

    return (
        <Grid item
            className={ className ? className : '' }
            key={ data.key }
            xsmobile={ xsmobile ? xsmobile : 'auto' }
            mobile={ mobile ? mobile : 'auto' }
            tablet={ tablet ? tablet : 'auto' }
            laptop={ laptop ? laptop : 'auto' }
            desktop={ desktop ? desktop : 'auto' }
        >

            { !isOverflowing && (
                <DataCard>
                    <DataCardContent>
                        <DataCardLabel>
                            { data.label }
                        </DataCardLabel>
                        <DataCardValue ref={ valueRef }>
                            { data.value }
                        </DataCardValue>
                    </DataCardContent>
                </DataCard>
            )}

            { isOverflowing && (
                <DataCard>
                    <ToolTipWrapper title={ data.value }
                        placement='right-end'
                        children={
                            <DataCardContent>
                                <DataCardLabel>
                                    { data.label }
                                </DataCardLabel>
                                <DataCardValue ref={ valueRef }>
                                        { data.value }
                                </DataCardValue>
                            </DataCardContent>
                        }
                    />
                </DataCard>
            )}

        </Grid>
    )
}