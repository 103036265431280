import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HttpLoadingHandler } from '../../utils';
import { HttpMethods, MFA_Reason, AppStatusCodes, Action } from '../../constants/enums';
import { global_BAD_DATE, global_EMPTY_UUID } from '../../constants';
import { IStepUpAuthState, IResults, IHeldAction } from '../../constants/interfaces';



const initialState: IStepUpAuthState = {
    stepUpRequested: false,
    stepUpApproved: false,
    stepUpAuthID: global_EMPTY_UUID,
    stepUpExpiry: global_BAD_DATE,
    showMfaInput: false,
    error: 0,
    pendingAction: null,
    vidOnly: false
}


export const StepUpSlice = createSlice({
    name: "StepUpAuth",
    initialState,
    reducers: {
        stepUpAuthApproved: ( state, action: PayloadAction< IResults > ) => {
            state.stepUpRequested = false;
            state.stepUpApproved = true;
            state.stepUpAuthID = action.payload.payload?.StepUpApproval;
            state.stepUpExpiry = action.payload.payload?.Expiry;
        },
        initiateStepUpProcess: ( state, action: PayloadAction< IHeldAction > ) => {
            state.stepUpRequested = true;
            state.showMfaInput = true;
            state.pendingAction = action.payload;
        },
        clearPendingAction: ( state ) => {
            state.pendingAction = null;
            state.showMfaInput = false;
        },
        resetStepUpAuth: ( state ) => {
            state.stepUpRequested = false;
            state.stepUpApproved = false;
            state.stepUpAuthID = global_EMPTY_UUID;
            state.stepUpExpiry = global_BAD_DATE;
            state.showMfaInput = false;
            state.error = 0;
            state.pendingAction = null;
            state.vidOnly = false;
        },
        vidRequired: ( state, action: PayloadAction< false | 'PHV' | 'EMV' > ) => {
            state.showMfaInput = true;
            state.vidOnly = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase( initiateStepUpAuth.fulfilled, ( state, action ) => {
            state.stepUpRequested = true;
            if ( action.payload.code !== AppStatusCodes.STEP_UP_REQUIRED ) {
                //state.error = action.payload.code;
            }
        })
        builder.addCase( verifyStepUpAuth.fulfilled, ( state, action ) => {
            if ( action.payload.code === AppStatusCodes.STEP_UP_APPROVED ) {
                console.log( 'StepUpAuthSlice', action.payload );
                state.stepUpRequested = false;
                state.stepUpApproved = true;
                state.stepUpAuthID = action.payload.payload?.StepUpApproval;
                state.stepUpExpiry = action.payload.payload?.Expiry;
            }
            if ( action.payload.code !== AppStatusCodes.STEP_UP_APPROVED ) {
                state.error = action.payload.code;
            }
        })
        builder.addCase( verifyStepUpAuth.rejected, ( state, action ) => {
            console.log( 'verifyStepUpAuth rejected: ', action.payload );
        })
    }
});


export const {
    stepUpAuthApproved,
    initiateStepUpProcess,
    clearPendingAction,
    resetStepUpAuth,
    vidRequired
} = StepUpSlice.actions;

export default StepUpSlice.reducer;


// Thunks
export const initiateStepUpAuth = createAsyncThunk( Action.INITIATE_STEPUP_AUTH, async ( data: MFA_Reason, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.GET, `/auth/stepUp/${ data }`, Action.INITIATE_STEPUP_AUTH, null, thunkAPI )
)

export const verifyStepUpAuth = createAsyncThunk( Action.VERIFY_STEPUP_AUTH, async ( data: { MFACode: string }, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.POST, '/auth/stepUp/verify', Action.VERIFY_STEPUP_AUTH, data, thunkAPI )
)

export const vidVerification = createAsyncThunk( Action.VERIFY_VID, async ( EMVCode: string , thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.GET, `/contact/verify/${ EMVCode }`, Action.VERIFY_VID, null, thunkAPI )
)