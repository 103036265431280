import { Box } from "@mui/system";
import { UsernameEntryContainer } from "../../components/styled";
import { StandardTextInput } from "../../components/UI";
import { RoundedButton } from "../../components/styled";

interface FormProps {
    currentView: 'user' | 'mfa' | 'verify' | 'reset' | 'complete';
    error: boolean;
    helpText: string;
    username: string;
    setUsername: ( v: string ) => void;
    onSubmit: () => void;
    onCancel: () => void;
    onKeyDown: ( e: React.KeyboardEvent ) => void;
}

export const DesktopUserForm = ({ currentView, error, helpText, username, setUsername, onSubmit, onCancel, onKeyDown }: FormProps) => (
    <UsernameEntryContainer view={ currentView }>
        <form onKeyDown={ onKeyDown }>
            <Box
                sx={{
                    alignItems: 'flex-start',
                    marginLeft: '3rem',
                    marginRight: '3rem',
                    paddingTop: '3rem',
                }}
            >

                <Box className="desktop-em-in">
                    <StandardTextInput
                        variant='standard'
                        label='Email Address'
                        placeholder='Email Address'
                        required
                        inputProps={{
                            'aria-label': 'Email Address',
                            sx: { color: '#1235ce' }
                        }}
                        size='medium'
                        error={ error }
                        value={ username }
                        onChange={ ( v ) => setUsername( v )}
                        autoComplete="new-password"
                        helpText={ helpText }
                    />
                </Box>

                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: '3rem',
                    }}
                >
                    <RoundedButton onClick={ onCancel }>
                        Cancel
                    </RoundedButton>

                    <RoundedButton onClick={ onSubmit }>
                        Submit
                    </RoundedButton>
                </Box>

            </Box>
        </form>
    </UsernameEntryContainer>
)