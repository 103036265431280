import React, { useEffect, useState } from "react";
import { MUX_DataGrid } from "../../components/UI/DataGrid";
import { useAppDispatch, useAppSelector } from "../../redux/Store";
import { fetchTransactionHistory } from "../../redux/AccessThunks";
import { Box, Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { columnBase } from "../../constants";
import { GridColDef } from "@mui/x-data-grid-premium";
import { ITransactionRecord } from "../../constants/interfaces";
import { formatDateTimeUTC_TZ } from "../../utils/commons";
import { cellValueFormating, columnHeaderFormating, operCostIDConverter, currencyFormatter } from "../../components/commonUIF";


interface ITransactionsGridProps {
    transactions: Array< ITransactionRecord >;
    xsmobile?: number;
    mobile?: number;
    tablet?: number;
    laptop?: number;
    desktop?: number;
}

export function TransactionsGrid({ 
    transactions,
    xsmobile = 4,
    mobile = 5,
    tablet = 8,
    laptop = 10,
    desktop = 12
}: ITransactionsGridProps ) {
    const theme = useTheme();
    const isXsMobile = useMediaQuery( theme.breakpoints.only( 'xsmobile' ) );
    const isMobile = useMediaQuery( theme.breakpoints.only( 'mobile' ) );
    const isTablet = useMediaQuery( theme.breakpoints.only( 'tablet' ) );
    const isLaptop = useMediaQuery( theme.breakpoints.only( 'laptop' ) );

    const columns: Array< GridColDef > = [
        {
            field: "EffectiveDate",
            renderHeader: () => columnHeaderFormating( "Effective Date" ),
            sortable: true,
            valueFormatter: ( value ) => formatDateTimeUTC_TZ( value, true ),
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                return cellValueFormating( params.formattedValue );
            } 
        },
        {
            field: "PostingDate",
            renderHeader: () => columnHeaderFormating( "Recorded Date" ),
            sortable: true,
            valueFormatter: ( value ) => formatDateTimeUTC_TZ( value, true ),
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                return cellValueFormating( params.formattedValue );
            } 
        },
        {
            field: "MoneyIn",
            renderHeader: () => columnHeaderFormating( "Money In" ),
            sortable: true,
            align: 'right',
            valueFormatter: ( value ) => currencyFormatter.format( value ),
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                return cellValueFormating( params.formattedValue );
            } 
        },
        {
            field: "MoneyOut",
            renderHeader: () => columnHeaderFormating( "Money Out" ),
            sortable: true,
            align: 'right',
            valueFormatter: ( value ) => currencyFormatter.format( value ),
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                return cellValueFormating( params.formattedValue );
            } 
        },
        {
            field: "ExpenseType",
            renderHeader: () => columnHeaderFormating( "Expense Type" ),
            sortable: true,
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                return cellValueFormating( params.value );
            } 
        },
        {
            field: "AccountType",
            renderHeader: () => columnHeaderFormating( "Account" ),
            sortable: true,
            renderCell: ( params ) => {
                if ( params.value === undefined ) return '';
                return cellValueFormating( params.value );
            } 
        }
    ]

    let visibleColumns = columns;
    if ( isLaptop ) {
        visibleColumns = columns.filter( column => [ 'EffectiveDate', 'PostingDate', 'MoneyIn', 'MoneyOut', 'ExpenseType' ].includes( column.field ) );
    }

    return (
        <Grid item
            xsmobile={ xsmobile } mobile={ mobile } tablet={ tablet } laptop={ laptop } desktop={ desktop }
        >
            <Box sx={{ maxWidth: '100%' }}>
                <Grid container
                    flexDirection='column'
                    justifyContent='center' 
                    alignItems='flex-start' 
                    columns={ columnBase }
                >
                    <Grid item
                        width="100%"
                        xsmobile={ 4 } mobile={ 6 } tablet={ 8 } laptop={ 10 } desktop={ 12 }
                    >
                        <MUX_DataGrid
                            disableColumnMenus
                            disableColumnSelector
                            columns={ visibleColumns }
                            rowId={ ( row: ITransactionRecord ) => row.TransactionID }
                            rows={ transactions }
                            hideFooter={ false }
                            pagination={ true }
                            includeInitialState={ true }
                            initialState={ {
                                pagination: { paginationModel: { pageSize: 20 } }
                            } }
                            pageSizeOptions={ [ 20, 30, 40, 50 ] } 
                            includeGroupingColDef={ false }
                        />
                    </Grid>

                </Grid>
            </Box>
        </Grid>
    )
}