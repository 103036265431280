import React from 'react';
import { toggleLoading, toggleError } from '../redux/slicers/AppState';
import { initiateStepUpProcess, vidRequired } from '../redux/slicers/StepUpAuth';
import { HttpHandler } from './HttpHandler';
import { IResults } from '../constants/interfaces';
import { HttpMethods, AppStatusCodes, Action } from '../constants/enums';


const successCodes = [ 
    AppStatusCodes.SUCCESS,
    AppStatusCodes.SUCCESS_MODIFIED_DATA,
    AppStatusCodes.AUTHENTICATED,
    AppStatusCodes.MFA_REQUIRED
]

const snackbarCodes = [
    AppStatusCodes.USER_LOCKED_OUT,
    AppStatusCodes.NO_RECORDS_FOUND,
    AppStatusCodes.MFA_MAX_ATTEMPTS_EXCEEDED,
    AppStatusCodes.MFA_INVALID_CODE
]

export async function HttpLoadingHandler( method: HttpMethods, endpoint: any, action: Action, data: any, thunkAPI: any ): Promise< IResults > {
    
    thunkAPI.dispatch( toggleLoading( true ) );
    
    try {

        const { success, statusCode, code, message, payload } = await HttpHandler({ method, endpoint, data, thunkAPI });

        if ( !success && !successCodes.includes( code ) && !snackbarCodes.includes( code ) ) {
            thunkAPI.dispatch( toggleError({ error: true, errorCode: code, errorMessage: message ? message : '' }) );
        }

        if ( code === AppStatusCodes.SERVER_INIT_STEPUP ) {
            thunkAPI.dispatch( initiateStepUpProcess({ action: action, data: data }) );
        }
        if ( code === AppStatusCodes.VID_VERIFICATION_REQUIRED ) {
            const vidType: false | 'PHV' | 'EMV' = payload;
            thunkAPI.dispatch( vidRequired( vidType ) );
        }

        // if ( snackbarCodes.includes( code ) ) {
        //     const alert = manageAlerts( code, message ? message : '' );
        //     thunkAPI.dispatch( addAlert({ id: Date.now(), message: alert.message, type: alert.type }) );
        // }

        return {
            success: success,
            statusCode: statusCode,
            code: code,
            message: message,
            payload: payload
        } as IResults;

    } catch ( error: any ) {
        
        thunkAPI.dispatch( toggleError({ error: true, errorCode: error.code, errorMessage: error.message }) );
        
        return error as IResults;

    } finally {

        thunkAPI.dispatch( toggleLoading( false ) );
    
    }
}

function manageAlerts( code: AppStatusCodes | number, resMessage: string ): { 
    message: string, 
    type: "error" | "success" | "warning" | "info" 
} {
    let type: "error" | "success" | "warning" | "info" = 'info';
    let message: string = '';
    switch ( code ) {
        case AppStatusCodes.USER_LOCKED_OUT:
            message = 'Your account has been locked';
            type = 'error';
            break;
        case AppStatusCodes.NO_RECORDS_FOUND:
            message = 'No records found matching your request';
            type = 'info';
            break;
        case AppStatusCodes.MFA_MAX_ATTEMPTS_EXCEEDED:
            message = 'Maximum MFA attempts exceeded';
            type = 'warning';
            break;
        case AppStatusCodes.MFA_INVALID_CODE:
            message = 'Invalid MFA code';
            type = 'warning';
            break;
        default:
            message = resMessage;
            break;
    }
    return { message, type };
}