import { useTheme } from '@mui/material';
import { ThumbUpIcon, ThumbDownIcon } from '../Icons';
import { ToolTipWrapper } from '../ToolTipWrapper';


export const InputMatchIndicator = ( { match }: { match: boolean } ) => {
    const isDark = useTheme().palette.mode === 'dark'
    return (
        <ToolTipWrapper 
            title={ match ? 'Inputs Match' : 'Inputs Do Not Match' }
            placement='right-end'
            children={ match 
                ? <ThumbUpIcon size='small' color='success' outlined={ isDark } />
                : <ThumbDownIcon size='small' color='error' outlined={ isDark } />
            }
            
        />
    )
}
