import React from 'react';
import { Typography, Grid, useTheme } from '@mui/material';
import { Footer } from '../styled';
import { Socials } from '../UI/Socials';

interface Props {
    appName?: string;
    version?: string;
}

export function BaseFooter({ version, appName }: Props ) {
    const theme = useTheme()
    const mode = theme.palette.mode


    const iconColor = mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main;

    return (
        <Footer className='footer' sx={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>
            <Grid container columns={ 2 } display='flex' flexDirection='row' width='100%'>

                <Grid item justifyContent='flex-start' alignItems='center' sx={{ marginTop: '0.4rem' }}
                    xsmobile={ 1 } mobile={ 1 } tablet={ 1 } laptop={ 1 } desktop={ 1 }
                >
                    <Typography variant='caption'>
                        © Copyright - InsideEdge - { appName }{ ' ' }V{ version }
                    </Typography>
                </Grid>

                <Grid item justifyContent='flex-end' alignItems='center'
                    xsmobile={ 1 } mobile={ 1 } tablet={ 1 } laptop={ 1 } desktop={ 1 }
                >
                    <Socials className='socials'
                        align='flex-end'
                        buttonSizes='small'
                        iconSizes='small'
                        IElogoPx='14px'
                        color={ iconColor }
                        WB
                        FB 
                        IG 
                        LI 
                        YT 
                        GH
                    />
                </Grid>
            </Grid>
        </Footer>
    )
}

