import React, { useState, useRef, ChangeEvent, KeyboardEvent, useEffect } from "react";
import { Box, Typography, FormHelperText, FormControl } from "@mui/material";
import { DigitInput } from "../../styled";
import { StepUpInputContainer } from "../../styled";
import { MFAMethod } from "../../../constants/enums";
import { useMediaWidth } from "../../../hooks";


interface SICInputProps {
    length: number;
    error: boolean;
    view: 'code' | 'loading';
    customMessage?: string;
    helpText?: string;
    onCompletion: ( v: string ) => void;
    onCancel: () => void;
}

export const StepUpInput = ({ length, error, view, customMessage, helpText, onCompletion, onCancel }: SICInputProps ) => {
    const [ values, setValues ] = useState< Array< string >>( new Array( length ).fill( '' ) );
    const inputs = useRef<( HTMLInputElement | null )[]>( [] );
    const isDesktop = useMediaWidth({ direction: 'up', breakPoint: 'tablet' });


    useEffect(() => {
        if ( view === 'code' ) {
            // Timeout used to allow the view to finish transitioning before focusing on the first input
            setTimeout( () => inputs.current[ 0 ]?.focus(), 500 );
        }
    }, [ view ]);

    useEffect(() => {
        const value = values.join( '' );
        if ( value.length === length ) {
            onCompletion( value );
            resetValues();
        }
    }, [ values ]);

    useEffect(() => {
        if ( error ) {
            resetValues();
        }
    }, [ error ]);
    
    const handleChange = ( e: ChangeEvent< HTMLInputElement | HTMLTextAreaElement >, index: number ) => {
        const { value } = e.target;
        if ( /^[0-9]$/.test( value ) || value === '' ) {
            const newvalues = [ ...values ];
            newvalues[ index ] = value;
            setValues( newvalues );

            if ( value !== '' && index < length - 1 ) {
                inputs.current[ index + 1 ]?.focus();
            }
        }
    }

    const handleKeyDown = ( e: KeyboardEvent< HTMLDivElement >, index: number ) => {
        if ( e.key === 'Backspace' && values[ index ] === '' && index > 0 ) {
            inputs.current[ index - 1 ]?.focus();
        }
    }

    const resetValues = () => setValues( new Array( length ).fill( '' ) );

    return (
        <StepUpInputContainer
            view={ view }
        >
            <FormControl 
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    justifyContent: 'center',
                    marginBottom: '-1rem',
                    marginLeft: '0.4rem'
                }}
            >
                {
                    values.map(( value, index ) => (
                        <DigitInput
                            key={ index }
                            value={ value }
                            onChange={ ( e ) => handleChange( e, index ) }
                            onKeyDown={ ( e ) => handleKeyDown( e, index ) }
                            autoComplete='off'
                            inputProps={{ maxLength: 1 }}
                            inputRef={ ( el ) => ( inputs.current[ index ] = el ) }
                            error={ error }
                            view={ view }
                            animationdelay={ '0.5' }
                        />
                    ))
                }
            </FormControl>
        </StepUpInputContainer>
    )
}