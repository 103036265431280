import React from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorFallback } from './utils';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Settings } from 'luxon';
import { ThemeContextProvider } from './theme';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Provider } from 'react-redux';
import { store } from './redux/Store'
import { HelmetProvider } from 'react-helmet-async';
import { RouteProvider } from './utils';
import AuthProvider from './context/AuthContext';
import * as Sentry from '@sentry/react';

const env: string = process.env.NODE_ENV || 'development';
// Set the default locale for the date picker
Settings.defaultLocale = 'en-AU';

Sentry.init({
    dsn: `${ process.env.SENTRY_DSN }`,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration()
    ],
    // Tracing options
    tracesSampleRate: 1.0,
    tracePropagationTargets: [ 
        "localhost", 
        /^https:\/\/autodashdev\.iedge\.com\/services/, 
        /^https:\/\/autodash\.iedge\.com\/services/ 
    ],
    // Session replay
    replaysSessionSampleRate: env === 'development' ? 0.0 : 1.0,
    replaysOnErrorSampleRate: env === 'development' ? 0.5 : 1.0,
});

const rootElement = document.createElement( 'div' );
rootElement.id = "root";
rootElement.className = "root-container";
document.body.appendChild( rootElement )
if ( !rootElement ) throw new Error( 'Failed to find the root element' );

const root = createRoot( rootElement );

root.render(
    <React.StrictMode>

            <HelmetProvider>
                <Provider store={ store }>
                    <ThemeContextProvider>
                        <LocalizationProvider dateAdapter={ AdapterLuxon } adapterLocale='en-au'>
                            <AuthProvider>
                                <RouteProvider />
                            </AuthProvider>
                        </LocalizationProvider>
                    </ThemeContextProvider>
                </Provider>
            </HelmetProvider>

    </React.StrictMode>
);