import React, { useEffect } from 'react';
import { Grid, useTheme } from '@mui/material';
import { PackageContent } from '../../components/styled';
import { useAppSelector, useAppDispatch } from '../../redux/Store';
import { getPackageLeaseAccDetail, fetchBudgetData } from '../../redux/AccessThunks';
import { PackageHeader } from './PackageHeader';
import { useNavigate } from 'react-router-dom';
import { RoutePaths, ReimbOperCostType } from '../../constants/enums'
import { columnBase } from '../../constants';
import { packageData } from './packageData';
import { financialData } from '../PackageAccount/financialData';
import { ReceiptIcon } from '../../components/UI/Icons';
import { BasicButton, VehicleDetails, AccountBalance, AccountInformation, StyledPieChart, BarGauge, BudgetUsageIndicators } from '../../components/UI';

export default function PackageDetail() {
    const { userDetails, budgetData, fullPackageDetail, leaseAccDetail } = useAppSelector(( state ) => state.Access )
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if ( !fullPackageDetail ) {
            navigate( RoutePaths.AUTHENTICATED_ROOT, { replace: true } )
        }
        if ( fullPackageDetail?.BenefitID ) {
            ( async () => {
                await dispatch( getPackageLeaseAccDetail({ BenefitID: fullPackageDetail.BenefitID }));
                // let { success, code, payload } = await dispatch( fetchTransactionHistory( fullPackageDetail.BenefitID )).unwrap();
                // if ( success && payload.length > 0 ) {
                //     setTransactions( payload as Array< ITransactionRecord > )
                // }
            })()
        }
        if ( budgetData.length === 0 && fullPackageDetail?.BenefitID ) {
            ( async () => {
                await dispatch( fetchBudgetData({ ContactID: userDetails.ContactID, BenefitID: fullPackageDetail.BenefitID }) )
            })()
        }
    }, [] )

    const header = {
        vehicle: fullPackageDetail?.VehicleMake ? `${ fullPackageDetail?.VehicleYear } ${ fullPackageDetail?.VehicleMake } ${ fullPackageDetail?.VehicleModel }` : '(No Data)',
        status: fullPackageDetail?.BenefitStatus ? fullPackageDetail.BenefitStatus : '(No Data)',
        rego: fullPackageDetail?.Rego ? fullPackageDetail.Rego : '(No Data)'
    }

    
    const { vehicleDetails, financeDetails } = packageData( fullPackageDetail )
    const { balanceInfo, accountInfo } = financialData( leaseAccDetail )
    const budget = budgetData.filter( item => item.CostTypeID !== ReimbOperCostType.LEASE_PAYMENT && item.CostTypeID !== ReimbOperCostType.FINANCE_DOCUMENTATION)

    return (
        <React.Fragment>
            <PackageHeader
                vehicle={ header.vehicle }
                status={ header.status }
                rego={ header.rego }
            />
            <PackageContent>
                <Grid container
                    p={ 1 }
                    display='flex'
                    flexDirection='column'
                    alignItems='flex-start'
                    columns={ columnBase } 
                    spacing={ 2 }
                    sx={{ flexFlow: 'wrap' }}
                >

                    <AccountBalance
                        balanceIsCredit={ balanceInfo.balanceIsCredit.value }
                        availableBalance={ balanceInfo.availableBalance.value }
                        currentBalance={ balanceInfo.currentBalance.value }
                        pendingTransactions={ balanceInfo.pendingTransactions.value }
                        fbtLibility={ balanceInfo.fbtLiabilityYTD.value }
                        lndLibility={ balanceInfo.lnd.value }
                        finalBalance={ balanceInfo.finalBalance.value }
                        xsmobile={ 8 } mobile={ 10 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
                    />

                    <VehicleDetails
                        vehicleDetails={ vehicleDetails }
                        financeDetails={ financeDetails }
                        xsmobile={ 8 } mobile={ 10 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
                    />

                    <AccountInformation
                        accountInfo={ accountInfo }
                        financeDetails={ financeDetails }
                        xsmobile={ 8 } mobile={ 10 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
                    />

                    {/* <StyledPieChart
                        totalValue={ budgetData.reduce(( acc, item ) => acc + item.TotalBudget, 0 ) }
                        data={ budgetData.map( item => ({ label: item.CostName, value: item.BudgetProrata })) }
                        width={ 200 }
                        height={ 300 }
                        xsmobile={ 4 } mobile={ 5 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
                    /> */}

                    {/* <BarGauge
                        data={ budgetData }
                        xsmobile={ 8 } mobile={ 10 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
                    /> */}
                    
                    <BudgetUsageIndicators 
                        data={ budget }
                        xsmobile={ 8 } mobile={ 10 } tablet={ 4 } laptop={ 5 } desktop={ 6 }
                    />

                </Grid>
            </PackageContent>
        </React.Fragment>
    )
}

/*
export interface IBudgetItem {
    CostID: number,
    CostTypeID: number,
    CostName: string,
    AmtExclGST: number,
    AmtGST: number,
    GSTApplies: boolean,
    TotalBudget: number,
    BudgetProrata: number,
    TotalSpent: number,
    Remaining: number,
    PcentSpent: number,
    PcentRemaining: number
}
*/