import React from 'react';
import { DateTime } from 'luxon';
import { currentDate } from '../../utils/commons';
import { DateOnlySelector } from '../styled';
import { useTheme } from '@mui/material';


interface DateSelectorProps {
    label: string;
    value: any;
    useDefaultValue?: boolean;
    variant: 'outlined' | 'filled' | 'standard';
    size: 'small' | 'medium';
    disabled?: boolean;
    onChange: ( date: any ) => void;
}

export function DateSelector({ ...props }: DateSelectorProps) {
    const { label, value, useDefaultValue, onChange, size, variant } = props;
    const theme = useTheme();
    let now = currentDate();


    const handleChange = (value: DateTime ) => {
        if ( value.isValid ) { // Check if the Luxon DateTime object is valid
          const selectedDate = value.toISO(); // Convert Luxon DateTime to ISO 8601 date string
          onChange( selectedDate ); // Call the onChange function with the selected date
        } else {
          console.log('Invalid date selected');
          // Handle invalid date if necessary
        }
      };

    return (
        <DateOnlySelector
            {...props }
            label={ label }
            format='dd/MM/yyyy'
            defaultValue={ useDefaultValue ? now : null }
            value={ value ? DateTime.fromISO( value ).toUTC() : now }
            onChange={ ( date: any ) => handleChange( date ) }
            maxDate={ now }
            disabled={ props.disabled || false }
            slotProps={{
                textField: {
                    fullWidth: true,
                    variant: variant,
                    size: size,
                    disabled: props.disabled || false,
                    sx: {
                        minWidth: '100%',
                        border: 'none',
                        borderBottom: `1px solid ${ theme.palette.inputs.border.color }`,
                        backgroundColor: 'transparent',
                        outline: 'none',

                        '&:hover': {
                            borderBottom: `1px solid ${ theme.palette.inputs.hover }`,
                        },
                        '& .MuiInput-underline:before': {
                            borderBottom: 'none !important', // Remove default underline
                            content: 'none',
                        },
                        '& .MuiInput-underline:after': {
                            borderBottom: 'none !important', // Remove the underline after the element
                        },
                        '& .MuiInputBase-root.Mui-focused:after': {
                            borderBottom: `1px solid ${ theme.palette.inputs.focus } !important`,
                            backgroundColor: 'transparent',
                            outline: 'none'
                        },
                        '&.Mui-disabled': {
                            borderBottom: `1px dotted ${ theme.palette.text.disabled } !important`,
                            color: theme.palette.text.disabled,
                            outline: 'none',
                        },
                        '&:hover:not(.Mui-disabled):before': {
                            borderBottom: 'none !important'
                        }

                    }
                }
            }}
        />
    )
}