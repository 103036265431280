import { Box, InputAdornment } from "@mui/material";
import { PasswdConfirmationContainer } from "../../components/styled";
import { StandardTextInput } from "../../components/UI";
import { RoundedButton } from "../../components/styled";
import { InputMatchIndicator } from "../../components/UI/Icons";

interface FormProps {
    currentView: 'user' | 'mfa' | 'verify' | 'reset' | 'complete';
    error: boolean;
    helpText: string;
    newPassword: string;
    confirmPassword: string;
    setNewPassword: ( v: string ) => void;
    setConfirmPassword: ( v: string ) => void;
    isValid: boolean;
    inValidMessage: string;
    onSubmit: () => void;
    onKeyDown: ( e: React.KeyboardEvent ) => void;
    onCancel: () => void;
}

export const DesktopConfirmForm = ({ 
    currentView,
    error,
    helpText,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    isValid,
    inValidMessage,
    onSubmit,
    onKeyDown,
    onCancel
}: FormProps) => (
    <PasswdConfirmationContainer view={ currentView }>
        <form onKeyDown={ onKeyDown }>
            <Box
                sx={{
                    alignItems: 'flex-start',
                    marginLeft: '3rem',
                    marginRight: '3rem',
                    paddingTop: '3rem',
                }}
            >

                <Box className="desktop-nwpd-in">
                    <StandardTextInput
                        variant='standard'
                        label='New Password'
                        placeholder='Enter New Password (minimum 10 characters)'
                        required
                        inputProps={{
                            'aria-label': 'New Password',
                            sx: { color: '#1235ce' }
                        }}
                        endAdmornment={
                            newPassword.length >= 10 && ( 
                                <InputAdornment position="end">
                                    <InputMatchIndicator match={ isValid } />
                                </InputAdornment>
                            )
                        }
                        size='medium'
                        type="password"
                        error={ error || !isValid }
                        value={ newPassword }
                        onChange={ ( v ) => setNewPassword( v ) }
                        autoComplete="new-password"
                        helpText={ helpText || newPassword.length >= 10 ? inValidMessage : '' }
                    />
                </Box>

                <Box className="desktop-conpd-in">
                    <StandardTextInput
                        variant='standard'
                        label='Confirm Password'
                        placeholder='Re-enter New Password (minimum 10 characters)'
                        required
                        inputProps={{
                            'aria-label': 'Confirm Password',
                            sx: { color: '#1235ce' }
                        }}
                        endAdmornment={
                            confirmPassword.length >= 10 && ( 
                                <InputAdornment position="end">
                                    <InputMatchIndicator match={ isValid } />
                                </InputAdornment>
                            )
                        }
                        size='medium'
                        type="password"
                        error={ error || !isValid }
                        value={ confirmPassword }
                        onChange={ ( v ) => setConfirmPassword( v ) }
                        autoComplete="new-password"
                        helpText={ helpText || confirmPassword.length >= 10 ? inValidMessage : '' }
                    />
                </Box>

                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: '3rem',
                    }}
                >
                    <RoundedButton onClick={ onCancel }>
                        Cancel
                    </RoundedButton>

                    <RoundedButton onClick={ onSubmit }>
                        Set Password
                    </RoundedButton>
                </Box>

            </Box>
        </form>
    </PasswdConfirmationContainer>
)