import React, { useState, useEffect } from 'react';
import { Typography, IconButton, Grid, Box } from '@mui/material';
import { IUserDetails, IUserAddress, IAdressDetails } from '../../../constants/interfaces';
import { columnBase } from '../../../constants';
import { AddressType } from '../../../constants/enums';
import { DataGroup, DataFieldConditional, StandardTextInput, BasicDropDownMenu, DataField } from '../../../components/UI';
import { addressTypeIDConverter, stateAcronymConverter } from '../../../components/commonUIF';
import { updateAddress } from '../../../redux/AccessThunks';
import { useAppDispatch } from '../../../redux/Store';
import { StateSelectOptions } from '../../../constants';
import { AddAddressDialog } from './AddAddressDialog';

interface AddressComponentProps {
    userDetails: IUserDetails;
    xsmobile: number;
    mobile: number;
    tablet: number;
    laptop: number;
    desktop: number;
}

interface AddressChangeTracking {
    AddressID: string;
}
interface AddressUpdate {
    AddressID: string;
    AddressTypeID?: number | null;
    newValue?: AddressValue | null;
    IsCurrent?: boolean;
}
interface AddressValue {
    UnitOrStreet?: string;
    Street?: string;
    City?: string;
    State?: string;
    Postcode?: number;
}

export function AddressDetails( { userDetails, xsmobile, mobile, tablet, laptop, desktop }: AddressComponentProps ) {
    const [ editAddress, setEditAddress ] = useState< 'view' | 'edit' >( 'view' )
    const [ showAddNewAddress, setAddNewAddress ] = useState( false )
    const [ showDeleteConfirm, setShowDeleteConfirm ] = useState( false )
    const [ confirmDelete, setConfirmDelete ] = useState< IUserAddress | null >( null )
    const [ changes, setChanges ] = useState< Array< AddressChangeTracking >>( [] )
    const [ hasBillingAddress, setHasBillingAddress ] = useState< boolean >( 
        userDetails.Addresses.find(( addr ) => addr.AddressTypeID === AddressType.BILLING ) ? true : false 
    )
    const [ hasMailAddress, setHasMailAddress ] = useState< boolean >(
        userDetails.Addresses.find(( addr ) => addr.AddressTypeID === AddressType.MAIL ) ? true : false
    )
    const [ addressArray, setAddressArray ] = useState(
        userDetails?.Addresses 
        ? userDetails.Addresses.map(( addr ) => ({
            key: `${ addr.AddressID }`,
            label: `${ addressTypeIDConverter( addr.AddressTypeID, addr.IsCurrent ) }`,
            value: {
                UnitOrStreet: addr.UnitOrApptNo,
                Street: addr.StreetAddress,
                City: addr.City,
                State: addr.State,
                Postcode: addr.Postcode
            },
            isEdit: true,
            isCurrent: addr.IsCurrent,
            AddressTypeID: addr.AddressTypeID
        })) : []
    )
    const dispatch = useAppDispatch()

    useEffect(() => {
        setHasBillingAddress( userDetails.Addresses.find(( addr ) => addr.AddressTypeID === AddressType.BILLING ) ? true : false )
        setHasMailAddress( userDetails.Addresses.find(( addr ) => addr.AddressTypeID === AddressType.MAIL ) ? true : false )
        setAddressArray(
            userDetails?.Addresses 
            ? userDetails.Addresses.map(( addr ) => ({
                key: `${ addr.AddressID }`,
                label: `${ addressTypeIDConverter( addr.AddressTypeID, addr.IsCurrent ) }`,
                value: {
                    UnitOrStreet: addr.UnitOrApptNo,
                    Street: addr.StreetAddress,
                    City: addr.City,
                    State: addr.State,
                    Postcode: addr.Postcode
                },
                isEdit: true,
                isCurrent: addr.IsCurrent,
                AddressTypeID: addr.AddressTypeID
            })) : []
        )
    }, [ userDetails.Addresses ])

    const handleClickDeleteIcon = ( AddressID: string ) => {
        let addr = userDetails.Addresses.find(( e ) => e.AddressID === Number( AddressID ))
        console.log( addr )
        if ( addr ) {
            setConfirmDelete( addr )
            setShowDeleteConfirm( true )
        }
    }

    return (
        <DataGroup 
            title='Address Details'
            subGroup
            isEditable
            hideBackground
            editing={ editAddress === 'edit' }
            onAddClick={ () => setAddNewAddress( true ) }
            xsmobile={ xsmobile } mobile={ mobile } tablet={ tablet } laptop={ laptop } desktop={ desktop }
        >
           
            { addressArray.length > 0 ? (
                addressArray.map(( addr ) => (
                    <Grid item 
                        key={ `${ addr.key }` }
                        container 
                        spacing={ 1 } 
                        columns={ columnBase }
                    >

                        <DataField
                            data={{
                                key: `${ addr.key }addressType`,
                                label: 'Address Type',
                                value: addr.label,
                                isEdit: false
                            }}
                            xsmobile={ 2 } mobile={ 2 } tablet={ 2 } laptop={ 2 } desktop={ 2 }
                        />
                        
                        {/* Street: string; */}
                        <DataField
                            data={{
                                key: `${ addr.key }address`,
                                label: 'Address',
                                value: `${ addr.value.UnitOrStreet ? addr.value.UnitOrStreet : '' }${ addr.value.UnitOrStreet ? ' ' : '' }${ addr.value.Street }, ${ addr.value.City }, ${ stateAcronymConverter( addr.value.State ) }, ${ addr.value.Postcode }`,
                                isEdit: false
                            }}
                            xsmobile={ 4 } mobile={ 3 } tablet={ 6 } laptop={ 8 } desktop={ 10 }
                        />

                    </Grid>
                ))
            ) : (
                <Grid item container spacing={ 1 } columns={ columnBase }>
                    <DataField
                        data={{
                            key: 'noAddressDetails',
                            label: 'Address Type',
                            value: 'Address Details',
                            isEdit: false
                        }}
                        xsmobile={ 2 } mobile={ 2 } tablet={ 2 } laptop={ 2 } desktop={ 2 }
                    />
                    
                    <DataField
                        data={{
                            key: 'noAddressDetails',
                            label: 'Address',
                            value: '( No Address Details )',
                            isEdit: false
                        }}
                        xsmobile={ 4 } mobile={ 3 } tablet={ 6 } laptop={ 8 } desktop={ 10 }
                    />
                </Grid>
            )}

            { !hasMailAddress && (
                <Grid item container spacing={ 1 } columns={ columnBase }>
                    <DataField
                        data={{
                            key: 'noMailingAddress',
                            label: 'Address Type',
                            value: 'Mailing Address',
                            isEdit: false
                        }}
                        xsmobile={ 2 } mobile={ 2 } tablet={ 2 } laptop={ 2 } desktop={ 2 }
                    />
                    
                    <DataField
                        data={{
                            key: 'noMailingAddress',
                            label: 'Address',
                            value: '( No Mailing Address Specified )',
                            isEdit: false
                        }}
                        xsmobile={ 4 } mobile={ 3 } tablet={ 6 } laptop={ 8 } desktop={ 10 }
                    />
                </Grid>
            )}

            { !hasBillingAddress && (
                <Grid item container spacing={ 1 } columns={ columnBase }>
                    <DataField
                        data={{
                            key: 'noBillingAddress',
                            label: 'Address Type',
                            value: 'Billing Address',
                            isEdit: false
                        }}
                        xsmobile={ 2 } mobile={ 2 } tablet={ 2 } laptop={ 2 } desktop={ 2 }
                    />
                    
                    <DataField
                        data={{
                            key: 'noBillingAddress',
                            label: 'Address',
                            value: '( No Billing Address Specified )',
                            isEdit: false
                        }}
                        xsmobile={ 4 } mobile={ 3 } tablet={ 6 } laptop={ 8 } desktop={ 10 }
                    />
                </Grid>
            )}

            <AddAddressDialog
                show={ showAddNewAddress }
                close={ () => setAddNewAddress( false )}
                contactID={ userDetails.ContactID }
                existingAddresses={ userDetails.Addresses }
                dispatch={ dispatch }
            />

        </DataGroup>
    )
}