import { ReimbOperCostType, ContactUsSubject } from '../enums';
import { IReimbAttachment } from '../interfaces';
import { UUID } from '../../types';
import { global_BAD_DATE } from '../';

export class UserSession {
    UserID: string = '';
    ContactID: number = 0;
    FirstName: string = '';
    LastName: string = '';
    EmailAddress: string = '';
    Authenticated: boolean = false;
    AuthID: UUID = '00000000-0000-0000-0000-000000000000';
    StepUpApproved: boolean = false;
    StepUpAuthID: UUID = '00000000-0000-0000-0000-000000000000'; // This is not stored on the server-side session object
    StepUpSecretAuthID: UUID = '00000000-0000-0000-0000-000000000000'; // This is not sent to the client
    StepUpHash: string = ''; // This is not sent to the client
    StepUpExpiry: string = global_BAD_DATE;
}

export class ReimbursmentSubmission {
    ContactID: number = 0;                             
    SubmittedByID: number | null = null;               
    BenefitID: number = 0;                             
    BenefitDesc: string = '';                          
    Odometer: number | null = null;                    
    BenefitOperCostTypeID: ReimbOperCostType | 0 = 0;
    BenefitOperCostName: string = '';                
    PurchaseDate: string = '';                         
    SupplierCompanyName: string | null = null;         
    IsNoReferenceNumber: boolean = false;            
    SupplierRefText: string | null = null;             
    AmountIncTax: number = 0;                           
    AmountTax: number = 0;                            
    UserComment: string | null = null;                 
    BankAccountID: number | null = null;               
    IsGSTOverride: boolean = false;                    
    FileAttachments: Array< IReimbAttachment > = [];    
}

export class ContactRequest {
    RequestOrigin: string = 'InsideEDGE - AutoDash';
    SubmittedByContactID: number = 0;
    PackageID: number = 0;
    PackageDesc: string = '';
    SubjectID: ContactUsSubject = ContactUsSubject.NONE;
    SubjectString: string = '';
    Message: string = '';
    SendTo: string = 'admin@iedge.com.au';
}