import React, { useState, useEffect } from 'react';
import { Grid, IconButton, useTheme, Box } from '@mui/material';
import { IUserDetails, IUserPhone } from '../../../constants/interfaces';
import { DataGroup, DataFieldConditional, StandardTextInput, DataField } from '../../../components/UI';
import { phoneTypeIDConverter, formatPhoneByType } from '../../../components/commonUIF';
import { updatePhone } from '../../../redux/AccessThunks';
import { DeleteIcon } from '../../../components/UI/Icons';
import { useAppDispatch } from '../../../redux/Store';
import { AddPhoneDialog } from './AddPhoneDialog';
import { DeletePhoneDialog } from './DeletePhoneDialog';
import { columnBase } from '../../../constants';

interface PhoneComponentProps {
    userDetails: IUserDetails;
    xsmobile: number;
    mobile: number;
    tablet: number;
    laptop: number;
    desktop: number;
}

export function PhoneDetails( { userDetails, xsmobile, mobile, tablet, laptop, desktop }: PhoneComponentProps ) {
    const [ editPhone, setEditPhone ] = useState< 'view' | 'edit' >( 'view' )
    const [ showAddPhone, setShowAddPhone ] = useState( false )
    const [ showDeleteConfirm, setShowDeleteConfirm ] = useState( false )
    const [ confirmDelete, setConfirmDelete ] = useState< IUserPhone | null >( null )
    const [ changes, setChanges ] = useState< Array<{ PhoneID: string }>>( [] )
    const [ phoneArray, setPhoneArray ] = useState(
        userDetails?.PhoneNumbers 
        ? userDetails.PhoneNumbers.map(( phone ) => ({
            key: `${ phone.PhoneID }`,
            label: `${ phoneTypeIDConverter( phone.PhoneTypeID ) }` ,
            value: formatPhoneByType( phone.PhoneNumber, phone.PhoneTypeID ),
            isEdit: true
        })) : []
    )
    const dispatch = useAppDispatch()
    const theme = useTheme()
    let isDarkMode = theme.palette.mode === 'dark'

    useEffect(() => {
        setPhoneArray(
            userDetails?.PhoneNumbers 
            ? userDetails.PhoneNumbers.map(( phone ) => ({
                key: `${ phone.PhoneID }`,
                label: `${ phoneTypeIDConverter( phone.PhoneTypeID ) }`,
                value: formatPhoneByType( phone.PhoneNumber, phone.PhoneTypeID ),
                isEdit: true
            })) : []
        )
    }, [ userDetails.PhoneNumbers ])

    const onCancelChanges = () => {
        setChanges( [] )
        setPhoneArray(
            userDetails?.PhoneNumbers 
            ? userDetails.PhoneNumbers.map(( phone ) => ({
                key: `${ phone.PhoneID }`,
                label: `${ phoneTypeIDConverter( phone.PhoneTypeID ) }`,
                value: formatPhoneByType( phone.PhoneNumber, phone.PhoneTypeID ),
                isEdit: true
            })) : []
        )
        setEditPhone( 'view' )
    }


    const handleClickDeleteIcon = ( phoneID: string ) => {
        let phone = userDetails.PhoneNumbers.find(( p ) => p.PhoneID === Number( phoneID ))
        if ( phone ) {
            setConfirmDelete( phone )
            setShowDeleteConfirm( true )
        }
    }

    return (
        <DataGroup 
            title='Phone Numbers'
            subGroup
            isEditable
            hideBackground
            editing={ editPhone === 'edit' }
            onEditClick={ () => setEditPhone( editPhone === 'view' ? 'edit' : 'view' )}
            onAddClick={ () => setShowAddPhone( true ) }
            onCancelClick={ () => onCancelChanges() }
            xsmobile={ xsmobile } mobile={ mobile } tablet={ tablet } laptop={ laptop } desktop={ desktop }
        >
           
            { phoneArray ? (
                phoneArray.map(( phone ) => (
                    <DataFieldConditional
                        key={ phone.key }
                        condition={ editPhone === 'view' }
                        data={ phone }
                        desktop={ phoneArray.length > 2 ? 4 : phoneArray.length < 2 ? 12 : 6 }
                        xsmobile={ 4 } mobile={ 5 } tablet={ 8 } laptop={ phoneArray.length > 1 ? 5 : 10 } 
                    >
                        <Box
                            display='flex'
                            flexDirection='row'
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Box width={ `90%` }>
                                <DataField data={ phone } />
                            </Box>

                            <Box 
                                flexGrow={1}
                                display='flex'
                                flexDirection='row'
                                justifyContent='center'
                            >
                                <IconButton
                                    size='medium'
                                    onClick={ () => handleClickDeleteIcon( phone.key ) }
                                    sx={{
                                        color: theme.palette.dataGroupIconButtons.deleteIcon,
                                    }}
                                >
                                    <DeleteIcon outlined={ isDarkMode } color='inherit' size='small' />
                                </IconButton>
                            </Box>
                        </Box>
                    </DataFieldConditional>
                ))
            ) : (
                <Grid item container spacing={ 1 } columns={ columnBase }>
                    <DataField
                        data={{
                            key: 'noPhoneDetails',
                            label: 'Phone Numbers',
                            value: '( No Phone Details Available )',
                            isEdit: false
                        }}
                        xsmobile={ 4 } mobile={ 3 } tablet={ 4 } laptop={ 5 } desktop={ 4 }
                    />
                </Grid>
            )}

            <AddPhoneDialog
                show={ showAddPhone }
                close={ () => setShowAddPhone( false ) }
                contactID={ userDetails.ContactID }
                dispatch={ dispatch }
            />
           
            <DeletePhoneDialog
                show={ showDeleteConfirm }
                close={ () => { setShowDeleteConfirm( false ), setConfirmDelete( null ), setEditPhone( 'view' ) } }
                contactID={ userDetails.ContactID }
                phoneID={ confirmDelete?.PhoneID || 0 }
                phoneTypeID={ confirmDelete?.PhoneTypeID || 0 }
                phoneNumber={ confirmDelete?.PhoneNumber || '' }
                isOnlyPhone={ userDetails.PhoneNumbers.length === 1 }
                dispatch={ dispatch }
            />

        </DataGroup>
    )
}