import React, { useState, useEffect } from 'react';
import { SearchIcon, DeleteIcon } from '../../../components/UI/Icons';
import { columnBase } from '../../../constants';
import { IAddBankAccount, IBankAccount } from '../../../constants/interfaces';
import { useAppDispatch, useAppSelector } from '../../../redux/Store';
import { fetchBankAccounts, addNewBankAccount, deleteBankAccount } from '../../../redux/AccessThunks'; 
import { switchAccType, switchBankAccApprovalStatus, formatDateTimeUTC_TZ } from '../../../utils/commons';
import { columnHeaderFormating, cellValueFormating } from '../../../components/commonUIF';
import { BasicButton, StandardTextInput } from '../../../components/UI';
import { AddAccountDialog } from '../AddBankAccountDialog';
import { 
    Box,
    Grid,
    Table,
    TableBody,
    TableHead,
    TableContainer,
    TableRow,
    TableCell,
    useMediaQuery,
    IconButton,
    useTheme,
    Paper
} from '@mui/material';



export function BankAcounts() {
    const { userDetails, registeredBankAccounts } = useAppSelector(( state ) => state.Access );
    const [ showAddAccountDialog, setShowAddAccountDialog ] = useState< boolean >( false );
    const [ accounts, setAccounts ] = useState< Array< IBankAccount >>( registeredBankAccounts || [] );
    const [ searchValue, setSearchValue ] = useState< string >( '' );
    const [ newAccountValues, setNewAccountValues ] = useState< IAddBankAccount >({
        ContactID: userDetails.ContactID,
        BSB_P1: '',
        BSB_P2: '',
        AccNumber: '',
        AccFormalName: '',
        AccNickname: ''
    });
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const isMobile = useMediaQuery( theme.breakpoints.down( 'mobile' ) );
    const isTablet = useMediaQuery( theme.breakpoints.down( 'tablet' ) );
    const isLaptop = useMediaQuery( theme.breakpoints.down( 'laptop' ) );

    useEffect(() => {
        if ( registeredBankAccounts.length === 0 && userDetails.ContactID !== 0 ) {
            ( async () => await dispatch( fetchBankAccounts({ ContactID: userDetails.ContactID, ActiveOnly: 1 }) ))();
        }
    }, []);

    async function handleAddAccount() {
        setShowAddAccountDialog( false );

        let response = await dispatch( addNewBankAccount( newAccountValues ) ).unwrap();
        let { success, code } = response;
        if ( success ) {
            alert( 'Bank Account Added Successfully' ); // TODO: Replace with a snackbar
            setNewAccountValues({ 
                ContactID: userDetails.ContactID, 
                BSB_P1: '', 
                BSB_P2: '', 
                AccNumber: '', 
                AccFormalName: '', 
                AccNickname: '' 
            });
        }
        if ( !success ) {
            alert( 'Failed to add Bank Account' ); // TODO: Replace with a snackbar
            setNewAccountValues({ 
                ContactID: userDetails.ContactID, 
                BSB_P1: '', 
                BSB_P2: '', 
                AccNumber: '', 
                AccFormalName: '', 
                AccNickname: '' 
            });
        }
    }

    async function handleDeleteAccount( accID: number ) {
        let response = await dispatch( deleteBankAccount({ ContactID: userDetails.ContactID, AccID: accID }) )
    }

    function handleSearch( value: string ) {
        setSearchValue( value );
        const filter = registeredBankAccounts.filter(( acc ) => {
            let searchValue = value.toLowerCase();
            let accFormalName = acc.AccFormalName.toLowerCase();
            let accNickname = acc.AccNickname.toLowerCase();
            let BSB = acc.AccBSB;
            let accNum = acc.AccNumber;

            let returnVal;
            switch ( true ) {
                case accFormalName.includes( searchValue ):
                    returnVal = acc;
                    break;
                case accNickname.includes( searchValue ):
                    returnVal =  acc;
                    break;
                case BSB.includes( searchValue ):
                    returnVal = acc;
                    break;
                case accNum.includes( searchValue ):
                    returnVal = acc;
                    break;
                default:
                    break;
            }
            return returnVal;
        });
        setAccounts( filter );
    }

    return (
        <Box sx={{ maxWidth: '100%', minWidth: '100%', p: 1 }}>
            <Grid container
                flexDirection='column'
                justifyContent='center' 
                alignItems='stretch' 
                columns={ columnBase }
                spacing={ 1 }
                p={ 1 }
                width='100%'
                wrap='wrap'
            >

                <Grid item
                    xsmobile={ 4 } mobile={ 5 } tablet={ 8 } laptop={ 10 } desktop={ 12 }
                >
                    <TableContainer 
                        component={ Paper }
                        sx={{ width: '100%', backgroundColor: isDarkMode ? theme.palette.overlays.level1 : theme.palette.background.paper }}
                    >
                        <Box
                            className="table-search- and-add-container"
                            sx={{ display: 'flex', flexDirection: 'row', width: '100%', paddingLeft: '1rem', marginTop: '0.5rem'  }}
                        >
                            <div style={{ 
                                    width: '30%',
                                    alignItems: 'flex-start'
                                }}
                            >
                                <StandardTextInput 
                                    variant='standard'
                                    size='small'
                                    label='Search'
                                    placeholder='Account Name, BSB, etc...'
                                    value={ searchValue || '' }
                                    onChange={ ( v ) => handleSearch( v ) }
                                    endAdmornment={ <SearchIcon size='small' color='inherit' outlined={ isDarkMode } /> }
                                />
                            </div>
                            <div style={{ 
                                    display: 'flex',
                                    width: '70%',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    paddingRight: '1rem'
                                }}
                            >
                                <BasicButton
                                    size='small'
                                    onClickHandler={ () => setShowAddAccountDialog( true ) }
                                    text="Add Bank Account"
                                    iconPosition='start'
                                    variant='text'
                                />
                            </div>
                        </Box>
                        <Table sx={{ width: '100%', minWidth: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    { !isLaptop && (
                                        <TableCell align="left">
                                            { columnHeaderFormating( 'Nickname' ) }
                                        </TableCell>
                                    )}
                                    { !isMobile && (
                                        <TableCell align="left">
                                            { columnHeaderFormating( 'Account Name' ) }
                                        </TableCell>
                                    )}
                                    <TableCell align="right">
                                        { columnHeaderFormating( 'BSB' ) }
                                    </TableCell>
                                    <TableCell align="right">
                                        { columnHeaderFormating( 'Acc Number' ) }
                                    </TableCell>
                                    { !isMobile && (
                                        <TableCell align="right">
                                            { columnHeaderFormating( 'Created Date' ) }
                                        </TableCell>
                                    )}
                                    { !isTablet && (
                                        <TableCell align="left">
                                            { columnHeaderFormating( 'Account Type' ) }
                                        </TableCell>
                                    )}
                                    { !isLaptop && (
                                        <TableCell align="left">
                                            { columnHeaderFormating( 'Approval Status' ) }
                                        </TableCell>
                                    )}
                                    { !isMobile && (
                                        <TableCell align="left">
                                            { columnHeaderFormating( 'Status' ) }
                                        </TableCell>
                                    )}
                                    <TableCell align="center">
                                        { columnHeaderFormating( 'Actions' ) }
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { registeredBankAccounts.length > 0 && accounts.map(( acc, i ) => (
                                    <TableRow key={ i } >
                                        { !isLaptop && (
                                            <TableCell>
                                                { cellValueFormating( acc.AccNickname || 'N/A' ) }
                                            </TableCell>
                                        )}
                                        { !isMobile && (
                                            <TableCell>
                                                { cellValueFormating( acc.AccFormalName ) }
                                            </TableCell>
                                        )}
                                        <TableCell align="right">
                                            { cellValueFormating( acc.AccBSB ) }
                                        </TableCell>
                                        <TableCell align="right">
                                            { cellValueFormating( acc.AccNumber ) }
                                        </TableCell>
                                        { !isMobile && (
                                            <TableCell align="right">
                                                { cellValueFormating( formatDateTimeUTC_TZ( acc.CreatedDate, true, true ) ) }
                                            </TableCell>
                                        )}
                                        { !isTablet && (
                                            <TableCell>
                                                { cellValueFormating( switchAccType( acc.AccTypeID ) ) }
                                            </TableCell>
                                        )}
                                        { !isLaptop && (
                                            <TableCell>
                                                { cellValueFormating( switchBankAccApprovalStatus( acc.ApprovalStatusID ) ) }
                                            </TableCell>
                                        )}
                                        { !isMobile && (
                                            <TableCell>
                                                { cellValueFormating( acc.IsActive ? 'Active' : 'Inactive' ) }
                                            </TableCell>
                                        )}
                                        <TableCell align="center">
                                            <IconButton onClick={ () => handleDeleteAccount( acc.AccID ) }>
                                                <DeleteIcon size="small" outlined={ isDarkMode } color="warning" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                { registeredBankAccounts.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={ 9 }>
                                            { cellValueFormating( 'No Bank Accounts Found' ) }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <AddAccountDialog 
                show={ showAddAccountDialog }
                accountValues={ newAccountValues }
                setAccountValues={ setNewAccountValues }
                onAddAccount={ handleAddAccount }
                close={ () => {
                    setShowAddAccountDialog( false );
                    setNewAccountValues({ 
                        ContactID: userDetails.ContactID, 
                        BSB_P1: '', 
                        BSB_P2: '', 
                        AccNumber: '', 
                        AccFormalName: '', 
                        AccNickname: ''
                    });
                }}
            />
        </Box>
    )
}