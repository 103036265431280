import React, { useState, useEffect } from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { Typography, Box, useTheme } from '@mui/material';
import { CheckCircleIcon } from '../Icons';
import { set } from 'node_modules/cypress/types/lodash';


interface UploadIndicatorProps {
    loading: boolean;
    error: boolean;
    minimumLoadingTime?: number;
}

function CircularProgressIndicator(
    props: CircularProgressProps & { value: number },
) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" { ...props } />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    sx={{ color: 'text.secondary' }}
                >
                    { `${ Math.round( props.value ) }%` }
                </Typography>
            </Box>
        </Box>
    );
}


export const UploadProgress = ({ loading, error, minimumLoadingTime = 5000 }: UploadIndicatorProps ) => {
    const [ progress, setProgress ] = useState< number >( 10 );
    const [ showSuccess, setShowSuccess ] = useState< boolean >( false );
    const [ showProgress, setShowProgress ] = useState< boolean >( true );
    const isDark = useTheme().palette.mode === 'dark';


    useEffect(() => {
        let timer: NodeJS.Timeout;
        let loadingComplete: boolean = false;

        if ( loading && error ) {
            setShowProgress( false );
        }

        if ( loading && !loadingComplete || !loading && !loadingComplete ) {
            // Progress update
            timer = setInterval(() => {
                setProgress(( prevProgress ) => ( prevProgress >= 100 ? 100 : prevProgress + 10 ));
            }, 800 );
        }

        const minTimer = setTimeout(() => {
            loadingComplete = true;
            if ( !loading && !error ) {
                setProgress( 100 );
                // After 2 seconds show success icon
                setTimeout(() => {
                    setShowProgress( false );
                    setShowSuccess( true );
                }, 2000 ); 
            } else if ( !loading && error ) {
                setShowProgress( false );
            } else if ( loading && loadingComplete && !error ) {
                setProgress( 90 );
                setTimeout(() => {
                    setShowProgress( false );
                }, 1000 );
            }
        }, minimumLoadingTime );

        return () => {
            clearInterval( timer );
            clearTimeout( minTimer );
        };
    }, [ loading, error, minimumLoadingTime ]);

    return (
        showProgress ? (
            <CircularProgressIndicator value={ progress } />
        ) : showSuccess ? (
            <CheckCircleIcon outlined={ isDark } color='success' size='large' />
        ) : null
    )
}