import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { ResponsiveFontSizesOptions } from "@mui/material/styles/responsiveFontSizes";
import { PaletteMode, PaperProps } from "@mui/material";
import ttProMedium from "@shared/assets/fonts/TT_Commons_Pro_Medium.woff2";
import ttProDemiBold from "@shared/assets/fonts/TT_Commons_Pro_DemiBold.woff2";
import ttProRegular from "@shared/assets/fonts/TT_Commons_Pro_Regular.woff2";
import GeneratePalette from "./GeneratePalette";


export default function GenerateTheme( mode: PaletteMode ) {
    const palette = GeneratePalette( mode )

    const responsiveFontOptions: ResponsiveFontSizesOptions = {
        breakpoints: [
            'xsmobile',
            'mobile',
            'tablet',
            'laptop',
            'desktop'
        ],
        disableAlign: false,
        factor: 2 // Changes the responsivness of fonts.Must be >1 lower means more responsive
    }

    let theme = createTheme({
        palette: palette,
        breakpoints: {
            values: {
                xsmobile: 0, // 0-600 4 columns - XsMobile
                mobile: 600, // 600-840 6 columns - Mobile
                tablet: 840, // 840-1024 8 columns - Tablet
                laptop: 1300, // 1024-1200 10 columns - Laptop  
                desktop: 1800  // >1800 12 columns - Desktop 
            }
        },
        transitions: {
            easing: {
                // This is the most common easing curve.
                easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
                // Objects enter the screen at full velocity from off-screen and slowly decelerate to a resting point.
                easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
                // Objects leave the screen at full velocity. They do not decelerate when off-screen.
                easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
                // The sharp curve is used by objects that may return to the screen at any time.
                sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
            },
            duration: {
                shortest: 150,
                shorter: 200,
                short: 250,
                // most basic recommended timing
                standard: 300,
                // this is to be used in complex animations
                complex: 375,
                // recommended when something is entering screen
                enteringScreen: 225,
                // recommended when something is leaving screen
                leavingScreen: 195,
            }
        },
        typography: {
            fontFamily: "'TT Commons Pro', 'Roboto'",
            fontSize: 11,
            h1: {
                fontSize: 30,
                fontWeight: 1000,
                lineHeight: 2,
                letterSpacing: 0.5
            },
            h2: {
                fontSize: 30,
                fontWeight: 1000,
                lineHeight: 1,
                letterSpacing: 0
            },
            h3: {
                fontSize: 24,
                fontWeight: 1000,
                lineHeight: 1,
                letterSpacing: 0.5,
            },
            h4: {
                fontSize: 18,
                fontWeight: 1000,
                lineHeight: 1,
                letterSpacing: 0.5
            },
            h5: {
                fontSize: 16,
                fontWeight: 1000,
                lineHeight: 1,
                letterSpacing: 1
            },
            h6: {
                fontSize: 14,
                fontWeight: 1000,
                lineHeight: 1.5,
                letterSpacing: 0.5,
            },
            subtitle1: {
                fontSize: 16,
                fontWeight: 900,
                lineHeight: 1,
                letterSpacing: 1,
            },
            subtitle1Bold: {
                fontSize: 16,
                fontWeight: 1000,
                lineHeight: 1,
                letterSpacing: 1,
            },
            subtitle2: {
                fontSize: 15,
                fontWeight: 900,
                lineHeight: 1,
                letterSpacing: 1,
            },
            tableHeader: {
                fontSize: 13,
                fontWeight: 1000,
                lineHeight: 1,
                letterSpacing: 0.5
            },
            tableValue: {
                fontSize: 12,
                fontWeight: 800,
                lineHeight: 1,
                letterSpacing: 0.5
            },
            dataGroupSubTitle: {
                fontSize: 12,
                fontWeight: 900,
                lineHeight: 1,
                letterSpacing: 0.7,
            },
            dataGridHeader: {
                fontSize: 13,
                fontWeight: 900,
                lineHeight: 1,
                letterSpacing: 0.7,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },
            dataGridCell: {
                fontSize: 12,
                fontWeight: 900,
                lineHeight: 1,
                letterSpacing: 0.5,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },
            dataLabel: {
                fontSize: 12,
                fontWeight: 1000,
                lineHeight: 1,
                letterSpacing: 0.4,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },
            dataValue: {
                fontSize: 12,
                fontWeight: 900,
                lineHeight: 1,
                letterSpacing: 0.4,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },
            tabHeadings: {
                fontSize: 13,
                fontWeight: 900,
                lineHeight: 1,
                letterSpacing: 0.5
            },
            body1: {
                fontSize: 12,
                fontWeight: 800,
                lineHeight: 1.3,
                letterSpacing: 0.1,
            },
            body1Bold: {
                fontSize: 12,
                fontWeight: 1000,
                lineHeight: 1.3,
                letterSpacing: 0.2
            },
            body2: {
                fontSize: 10,
                fontWeight: 800,
                lineHeight: 1.5,
                letterSpacing: 0,
            },
            button: {
                fontSize: 12,
                fontWeight: 900,
                lineHeight: 1.5,
                letterSpacing: 0.5,
                textTransform: 'none'
            },
            caption: {
                fontSize: 10,
                fontWeight: 900,
                lineHeight: 1.5,
                letterSpacing: 0.1,
            }
        },
        components: {
            MuiTooltip: {
                defaultProps: {
                    PopperProps:{
                        disablePortal: true // Stops the scrollbar poppingup everytime a popover apears on the screen
                    }
                }
            },
            MuiUseMediaQuery: {
                defaultProps: {
                    noSsr: true // Turns off Server Side Rendering functions in theme speeding up the responsive layer
                }
            },
            MuiDrawer: {
                defaultProps: {
                    PaperProps: {

                    }
                }
            },
            MuiCssBaseline: {
                styleOverrides: `
                    @font-face {
                        font-family: 'TT Commons Pro';
                        src: url(${ttProRegular}) format('woff2');
                        display: swap;
                        font-weight: 800;
                        font-style: normal;
                    }
                    @font-face {
                        font-family: 'TT Commons Pro';
                        src: url(${ttProMedium}) format('woff2');
                        display: swap;
                        font-weight: 900;
                        font-style: normal;
                    }
                    @font-face {
                        font-family: 'TT Commons Pro';
                        src: url(${ttProDemiBold}) format('woff2');
                        display: swap;
                        font-weight: 1000;
                        font-style: normal;
                    }
                `
            }
        }
    })

    // reponsive fonts and color augmentation takes place below
    return theme = responsiveFontSizes( createTheme( theme, {
        palette: {
            augmentedBlue: theme.palette.augmentColor({
                color: {
                    main: "#29b6f6"
                },
                name: 'augmentedBlue'
            })
        }
    }), responsiveFontOptions )
}