import React from 'react';
import { LogoContainer, SymbolContainer, FormLogoContainer } from '../styled';
import { styled, Box, Theme } from '@mui/material';
import BrandedLogoDark from '@shared/assets/images/logo-dark-mode.svg';
import BrandedLogoLight from '@shared/assets/images/logo-light-mode.svg';
import BrandSymbol from '@shared/assets/images/favicon-dark.svg';

interface BrandLogoProps {
    size?: string;
    theme: Theme;
}
export const BrandedLogoBanner = () => {
    
    const logoImage = BrandedLogoLight;

    const Logo = styled( 'img' ) ({
        height: '10rem'
    });

    return (
        <LogoContainer className='logo-container'>
            <Logo src={ logoImage } alt="Inside Edge Logo" />
        </LogoContainer>
    )
};

export function BrandedSymbol() {
    const symbolImage = BrandSymbol;

    const Symbol = styled( 'img' ) ({
        height: '73rem',
    });

    return (
        <SymbolContainer className='sym-container'>
            <Symbol src={ symbolImage } alt="Inside Edge Symbol" />
        </SymbolContainer>
    )
}

export function FormLogoBanner() {
    
    const logoImage = BrandedLogoLight;

    const Logo = styled( 'img' ) ({
        height: '13rem',
    });

    return (
        <FormLogoContainer>
            <Logo src={ logoImage } alt="Inside Edge Logo" />
        </FormLogoContainer>
    )
};

export const NavMenuLogo = ({ size, theme }: BrandLogoProps ) => {
    const mode = theme.palette.mode;
    const logoImage = mode === 'dark' ? BrandedLogoDark : BrandedLogoLight;

    const Logo = styled( 'img' ) ({
        height: size
    });

    return (
        <Box className='logo-container'
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                marginLeft: '0.8rem'
            }}
        >
            <Logo src={ logoImage } alt="Inside Edge Logo" />
        </Box>
    )
};