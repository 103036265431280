import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { IUserAddress } from '../../../constants/interfaces';
import { AddressType } from '../../../constants/enums';
import { addNewAddress } from '../../../redux/AccessThunks';
import { AppDispatch } from '../../../redux/Store';
import { BasicDropDownMenu, StandardTextInput, RadioToggleInput } from '../../../components/UI';
import { Dialog_DetailEdit } from '../../../components/UI';
import { StateSelectOptions } from '../../../constants';


interface AddAddressDialogProps {
    show: boolean;
    close: () => void;
    contactID: number;
    existingAddresses: Array< IUserAddress >;
    dispatch: AppDispatch;
}

export const AddAddressDialog = ({ ...props }: AddAddressDialogProps ) =>  {
    const { show, close, contactID, existingAddresses, dispatch } = props
    const [ newAddress, setNewAddress ] = useState< IUserAddress >({
        ContactID: contactID,
        AddressID: 0,
        AddressTypeID: AddressType.HOME,
        UnitOrApptNo: null,
        StreetAddress: '',
        City: '',
        State: '',
        Postcode: 0,
        IsCurrent: false
    })

    const onCancel = () => {
        setNewAddress({
            ContactID: contactID,
            AddressID: 0,
            AddressTypeID: AddressType.HOME,
            UnitOrApptNo: null,
            StreetAddress: '',
            City: '',
            State: '',
            Postcode: 0,
            IsCurrent: false
        })
        close()
    }

    const onAddAddress = async () => {

        // Validation Logic Required Like Crazy.... Help dont have time! \\
        // if ( existingAddresses.filter( a => a.AddressTypeID === newAddress.AddressTypeID ).length > 0 ) {
        //     return alert( 'You already have an existing' )
        // }
        if ( newAddress.StreetAddress === '' ) {
            return alert( 'The street part of the address is missing' )
        }
        if (  newAddress.City === '' ) {
            return alert( 'The suburb or city is missing in this address' )
        }
        if ( newAddress.State === '' ) {
            return alert( 'Um, and the state please?' )
        }
        // if ( newAddress.Postcode.toString.length < 3 || newAddress.Postcode.toString.length > 4 ) {
        //     return alert( 'Please enter a valid postcode' )
        // }

        await dispatch( addNewAddress( newAddress ))
        setNewAddress({
            ContactID: contactID,
            AddressID: 0,
            AddressTypeID: AddressType.HOME,
            UnitOrApptNo: null,
            StreetAddress: '',
            City: '',
            State: '',
            Postcode: 0,
            IsCurrent: false
        })
        close()
    }

    return (
        <Dialog_DetailEdit
            show={ show }
            title='Add New Address'
            confirmLabel='Add'
            cancelLabel='Cancel'
            slideDirection='up'
            onConfirm={ onAddAddress }
            onCancel={ onCancel }
        >
            <form>
                <Grid container
                    direction='column'
                    columns={ 2 }
                    spacing={ 1 }
                >
                    <Grid item>
                        <BasicDropDownMenu
                            menuLabel='Address Type'
                            selectID='AddressType'
                            size='small'
                            options={[
                                { key: 1, label: 'Home', value: AddressType.HOME },
                                { key: 2, label: 'Mailing Address', value: AddressType.MAIL },
                                { key: 3, label: 'Billing Address', value: AddressType.BILLING }
                            ]}
                            onChange={ ( v ) => setNewAddress({ ...newAddress, AddressTypeID: v  }) }
                        />
                    </Grid>

                    <Grid item>
                        <StandardTextInput
                            variant='standard'
                            size='small'
                            label='Unit/Apt No.'
                            value={ newAddress.UnitOrApptNo }
                            type='text'
                            onChange={ ( v ) => setNewAddress({ ...newAddress, UnitOrApptNo: v }) }
                        />
                    </Grid>

                    <Grid item>
                        <StandardTextInput
                            variant='standard'
                            size='small'
                            label='Street Address'
                            value={ newAddress.StreetAddress }
                            type='text'
                            onChange={ ( v ) => setNewAddress({ ...newAddress, StreetAddress: v }) }
                        />
                    </Grid>

                    <Grid item>
                        <StandardTextInput
                            variant='standard'
                            size='small'
                            label='Suburb/City'
                            value={ newAddress.City }
                            type='text'
                            onChange={ ( v ) => setNewAddress({ ...newAddress, City: v }) }
                        />
                    </Grid>

                    <Grid item>
                        <BasicDropDownMenu
                            menuLabel='State'
                            selectID='state'
                            size='small'
                            options={ StateSelectOptions }
                            onChange={ ( v ) => setNewAddress({ ...newAddress, State: v  }) }
                        />
                    </Grid>
                    
                    <Grid item>
                        <StandardTextInput
                            variant='standard'
                            size='small'
                            label='Postcode'
                            value={ newAddress.Postcode }
                            type='text'
                            onChange={ ( v ) => setNewAddress({ ...newAddress, Postcode: Number( v ) }) }
                        />
                    </Grid>

                </Grid>
            </form>
        </Dialog_DetailEdit>
    )
}