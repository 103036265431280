import { IFile } from '../../constants/interfaces';
import { Dialog_DetailEdit } from "../../components/UI";
import { Typography, Grid, Card } from '@mui/material';
import { fileDownload } from '../../utils/commons';

interface ViewAttachmentsDialogProps {
    show: boolean;
    attachments: Array< IFile >;
    close: () => void;
}

export const ViewAttachmentsDialog = ({ show, attachments, close }: ViewAttachmentsDialogProps ) => {

    async function onDownloadFile( file: IFile ) {
        if ( !file || !file.FileData ) {
            return alert( 'Error downloading file' ) // Will replace with dialog later
        }
        await fileDownload( 
            file.FileData, 
            `${ file.FileID }_${ file.FileNameOnly }`,
            file.FileExtension
        )
    }

    return (
        <Dialog_DetailEdit
            show={ show }
            title='Attachments'
            slideDirection='up'
            cancelLabel='Close'
            onCancel={ close }
        >
            <Grid container 
                spacing={ 1 }
                columns={ 1 }
                display={ 'flex' }
                flexDirection={ 'column' }
                justifyContent='center'
                alignItems='center'

            >
                {
                    attachments.map( ( file, index ) => (
                        <Grid item 
                            key={ index }
                            xsmobile={ 1 } mobile={ 1 } tablet={ 1 } laptop={ 1 } desktop={ 1 }
                        >
                            <Card style={{ 
                                    cursor: 'pointer',
                                    padding: '0.5rem',
                                    width: '6vw'
                                }}
                                onClick={ () => onDownloadFile( file ) }
                            >
                                <Typography 
                                    variant='body1' 
                                    color='primary' 
                                    style={{ cursor: 'pointer' }}
                                >
                                    { file.FileNameOnly }
                                </Typography>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </Dialog_DetailEdit>
    )
}
