import React, { useState, useEffect } from 'react';
import { IconButton, Grid, Box, useTheme } from '@mui/material';
import { IUserDetails, IUserEmail } from '../../../constants/interfaces';
import { DataGroup, DataFieldConditional, DataField } from '../../../components/UI';
import { emailTypeIDConverter } from '../../../components/commonUIF';
import { DeleteIcon } from '../../../components/UI/Icons';
import { useAppDispatch } from '../../../redux/Store';
import { AddEmailDialog } from './AddEmailDialog';
import { DeleteEmailDialog } from './DeleteEmailDialog';
import { columnBase } from '../../../constants';


interface EmailComponentProps {
    userDetails: IUserDetails;
    xsmobile: number;
    mobile: number;
    tablet: number;
    laptop: number;
    desktop: number;
}

export function EmailDetails( { userDetails, xsmobile, mobile, tablet, laptop, desktop }: EmailComponentProps ) {
    const [ editEmail, setEditEmail ] = useState< 'view' | 'edit' >( 'view' )
    const [ showAddEmail, setShowAddEmail ] = useState( false )
    const [ showDeleteConfirm, setShowDeleteConfirm ] = useState( false )
    const [ confirmDelete, setConfirmDelete ] = useState< IUserEmail | null >( null )
    const [ changes, setChanges ] = useState< Array<{ EmailID: string, newEmail?: string }>>( [] )
    const [ emailArray, setEmailArray ] = useState(
        userDetails?.EmailAddresses 
        ? userDetails.EmailAddresses.map(( email ) => ({
            key: `${ email.EmailID }`,
            label: `${ emailTypeIDConverter( email.EmailTypeID ) }` ,
            value: email.EmailAddress,
            isEdit: true,
            isPrimary: email.IsPrimary,
            isSendAllIncluded: email.IsSendAllIncluded
        })) : []
    )
    const dispatch = useAppDispatch()
    const theme = useTheme()
    let isDarkMode = theme.palette.mode === 'dark'

    useEffect(() => {
        setEmailArray(
            userDetails?.EmailAddresses 
            ? userDetails.EmailAddresses.map(( email ) => ({
                key: `${ email.EmailID }`,
                label: `${ emailTypeIDConverter( email.EmailTypeID ) }`,
                value: email.EmailAddress,
                isEdit: true,
                isPrimary: email.IsPrimary,
                isSendAllIncluded: email.IsSendAllIncluded
            })) : []
        )
    }, [ userDetails.EmailAddresses ])

    const onCancelChanges = () => {
        setChanges( [] )
        setEmailArray(
            userDetails?.EmailAddresses 
            ? userDetails.EmailAddresses.map(( email ) => ({
                key: `${ email.EmailID }`,
                label: `${ emailTypeIDConverter( email.EmailTypeID ) }`,
                value: email.EmailAddress,
                isEdit: true,
                isPrimary: email.IsPrimary,
                isSendAllIncluded: email.IsSendAllIncluded
            })) : []
        )
        setEditEmail( 'view' )
    }

    const handleClickDeleteIcon = ( emailID: string ) => {
        let email = userDetails.EmailAddresses.find(( e ) => e.EmailID === Number( emailID ))
        console.log( email )
        if ( email ) {
            setConfirmDelete( email )
            setShowDeleteConfirm( true )
        }
    }

    return (
        <DataGroup 
            title='Email Addresses'
            subGroup
            isEditable
            hideBackground
            editing={ editEmail === 'edit' }
            onEditClick={ () => setEditEmail( editEmail === 'view' ? 'edit' : 'view' )}
            onAddClick={ () => setShowAddEmail( true ) }
            onCancelClick={ () => onCancelChanges() }
            xsmobile={ xsmobile } mobile={ mobile } tablet={ tablet } laptop={ laptop } desktop={ desktop }
        >
           
            { emailArray.length > 0 ? (
                emailArray.map(( email ) => (
                    <DataFieldConditional
                        key={ email.key }
                        condition={ editEmail === 'view' }
                        data={ email }
                        desktop={ emailArray.length > 3 ? 3 : emailArray.length > 2 ? 4 : emailArray.length < 2 ? 12 : 6 }
                        xsmobile={ 4 } mobile={ 5 } tablet={ 8 } laptop={ emailArray.length > 1 ? 5 : 10 }
                    >
                        <Box
                            display='flex'
                            flexDirection='row'
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Box width={ `90%` }>
                                <DataField data={ email } />
                            </Box>

                            <Box 
                                flexGrow={1}
                                display='flex'
                                flexDirection='row'
                                justifyContent='center'
                            >
                                <IconButton
                                    size='medium'
                                    onClick={ () => handleClickDeleteIcon( email.key ) }
                                    sx={{
                                        color: theme.palette.dataGroupIconButtons.deleteIcon,
                                    }}
                                >
                                    <DeleteIcon outlined={ isDarkMode } color='inherit' size='small' />
                                </IconButton>
                            </Box>
                        </Box>
                    </DataFieldConditional>
                ))
            ) : (
                <Grid item container spacing={ 1 } columns={ columnBase }>
                    <DataField
                        data={{
                            key: 'noEmailDetails',
                            label: 'Email Address',
                            value: '( No Email Details Available )',
                            isEdit: false
                        }}
                        xsmobile={ 4 } mobile={ 3 } tablet={ 4 } laptop={ 5 } desktop={ 4 }
                    />
                </Grid>
            )}

            <AddEmailDialog
                show={ showAddEmail }
                close={ () => { setShowAddEmail( false ), setEditEmail( 'view' ) } }
                contactID={ userDetails.ContactID }
                dispatch={ dispatch }
            />
           
            <DeleteEmailDialog
                show={ showDeleteConfirm }
                close={ () => { setShowDeleteConfirm( false ), setConfirmDelete( null ), setEditEmail( 'view' ) } }
                contactID={ userDetails.ContactID }
                EmailID={ confirmDelete?.EmailID || 0 }
                EmailTypeID={ confirmDelete?.EmailTypeID || 0 }
                EmailAddress={ confirmDelete?.EmailAddress || '' }
                isOnlyEmail={ userDetails.EmailAddresses.length === 1 }
                dispatch={ dispatch }
            />

        </DataGroup>
    )
}