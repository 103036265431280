import React, { useEffect, useState } from 'react';
import { Grid, useTheme } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../redux/Store';
import { fetchTransactionHistory } from '../../redux/AccessThunks';
import { AccountContent } from '../../components/styled';
import { useNavigate } from 'react-router-dom';
import { AccountHeader } from './AccountHeader';
import { columnBase } from '../../constants';
import { RoutePaths } from '../../constants/enums';
import { ITransactionRecord } from '../../constants/interfaces';
import { TransactionsGrid, BasicButton } from '../../components/UI';
import { ArrowBackIcon } from '../../components/UI/Icons';
//import { NivoPieChart } from '../../components/UI';

export default function PackageAccount() {
    const { fullPackageDetail } = useAppSelector(( state ) => state.Access )
    const [ transactions, setTransactions ] = useState< Array< ITransactionRecord >>( [] )
    const theme = useTheme()
    const isDarkMode = theme.palette.mode === 'dark'
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if ( !fullPackageDetail ) {
            navigate( RoutePaths.AUTHENTICATED_ROOT, { replace: true } )
        }
        if ( fullPackageDetail?.BenefitID ) {
            ( async () => {
                let { success, code, payload } = await dispatch( fetchTransactionHistory( fullPackageDetail.BenefitID )).unwrap();
                if ( success && payload.length > 0 ) {
                    setTransactions( payload as Array< ITransactionRecord > )
                }
            })()
        }
    }, [] )
    
    const header = {
        vehicle: fullPackageDetail?.VehicleMake ? `${ fullPackageDetail?.VehicleYear } ${ fullPackageDetail?.VehicleMake } ${ fullPackageDetail?.VehicleModel }` : '(No Data)',
        status: fullPackageDetail?.BenefitStatus ? fullPackageDetail.BenefitStatus : '(No Data)',
        rego: fullPackageDetail?.Rego ? fullPackageDetail.Rego : '(No Data)'
    }

    return (
        <React.Fragment>

            <AccountHeader
                vehicle={ header.vehicle }
                status={ header.status }
                rego={ header.rego }
            />
        
            <AccountContent>
                <Grid container
                    p={ 1 }
                    display='flex'
                    flexDirection='column'
                    alignItems='flex-start'
                    columns={ columnBase }
                    spacing={ theme.spacing( 1 ) }
                    sx={{ flexFlow: 'wrap' }}
                >
                    <Grid item>
                        <BasicButton
                            color={ isDarkMode ? 'info' : 'primary' }
                            text='Back'
                            size='small'
                            iconPosition='start'
                            icon={ <ArrowBackIcon outlined={ isDarkMode ? true : false } color={ isDarkMode ? 'info' : 'inherit' } size='small' /> }
                            onClickHandler={ () => navigate( -1 ) }
                        />
                    </Grid>

                    <Grid item
                        xsmobile={ 4 } mobile={ 5 } tablet={ 8 } laptop={ 10 } desktop={ 12 }
                    >
                        <TransactionsGrid
                            transactions={ transactions }
                        />
                    </Grid>

                </Grid>
            </AccountContent>
        </React.Fragment>
    )
}