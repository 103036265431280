import React, { useState, useEffect, useContext } from 'react';
import { Switch, useTheme, IconButton } from '@mui/material';
import { LightModeIcon, DarkModeIcon } from './Icons';
import { ColorModeContext } from '../../theme'

interface ThemeSwitchProps {
    size: "small" | "medium",
    color: "default" | "error" | "secondary" | "primary" | "info" | "success" | "warning",
    disabled: boolean
}

export function DynamicThemeSwitch({ ...props }: ThemeSwitchProps ) {
    const { size, color, disabled } = props
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark' ? true : false
    const [ isChecked, setIsChecked ] = useState< boolean >( isDarkMode )
    const colorMode = useContext( ColorModeContext );

    const handleChange = () => {
        colorMode.toggleColorMode(),
        setIsChecked( !isChecked );
    } 
    
    // return <Switch disabled={ disabled } checked={ isChecked } onChange={ handleChange } color={ color } size={ size } />
    return (
        <IconButton onClick={ handleChange } size={ size } color={ color } disabled={ disabled }>
            {
                isChecked && <DarkModeIcon outlined={ false } size={ size } color='info' />
            }
            {
                !isChecked && <LightModeIcon outlined={ false } size={ size } color='primary' />
            }
        </IconButton>
    )
}